.sticky {
  position: sticky;
  top: 0;
  z-index: 3;
}

.transactions-header {
  @include Transition;
  &:not(.stickyTop) {

    background: $gray-2;
    border-bottom: 1px solid $gray-4;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  &.publicHeightGoDownOnScrollTop {
    top: 72px;
  }
  z-index: 3;
  &__content {
    padding: 24px 0;
  }
  &__counter {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray-8;
    &-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-top: 8px;
    }
  }
}


