.ant-input-number:focus, .ant-input-number-focused {
  border-color: $main;
  box-shadow: 0 0 0 2px rgba(153,	172,	242, .2);
}
.ant-input-number-affix-wrapper:focus, .ant-input-number-affix-wrapper-focused {
  border-color: $main;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(153,	172,	242, .2);
}
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-color: $main;
  border-right-width: 1px !important;
  z-index: 1;
}
.ant-input-number:hover {
  border-color: $main;
  border-right-width: 1px !important;
}