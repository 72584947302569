@media screen and (max-width: $xxl-media) {
  .sign {
    &-form_extra_wrapper {
      max-width: 708px;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .sign {
    &-form_extra_wrapper {
      max-width: 512px;
    }
  }
}

@media screen and (max-width: $more-md-media) {
  .sign {
    justify-content: center;
  }
}
@media screen and (max-width: $sm-media) {
  .sign-wrapper {
    background: $gray-3;
  }
  .sign {
    justify-content: flex-start;
    width: 100%;
    height: auto;
    &-form_extra_wrapper {
      max-width: 100%;
      margin-top: 0 !important;
    }
    &-form_wrapper {
      width: 100%;
      padding: 20px 16px 60px 16px;
      max-width: 100%;
      height: 100%;
      border-radius: 0;
    }
    &-title {
      margin-bottom: 40px;
      margin-top: 12px; // OAuth hidden
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
    }
    &-icons {
      .item {
        div {
          margin-bottom: 8px;
          width: 40px;
          height: 40px;
          svg {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    &-or {
      max-width: 100%;
    }
    &-footer {
      &__lang {
        margin-top: 24px;
      }
    }
  }
}
