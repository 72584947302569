.api-keys {
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    &__text {
      max-width: 436px;
      font-size: 16px;
      line-height: 24px;
      color: $gray-8;
      margin-bottom: 24px;
    }
  }
  &__create-key {
    height: 40px;
    width: 282px;
    font-size: 16px;
    line-height: 24px;
  }
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__item {
    margin-top: 16px;
    width: 100%;
    padding: 16px 24px;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &__datetime, &__naming {
      margin-right: 8px;
      display: flex;
      flex-direction: column;
    }
    &__datetime {
      width: 137px;
    }
    &__date {
      font-size: 16px;
      line-height: 22px;
    }
    &__time {
      margin-top: 4px;
      font-size: 14px;
      line-height: 22px;
      color: $gray-7;
    }
    &__naming {
      overflow: hidden;
      width: 440px;
    }
    &__name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__public_id {
      margin-top: 2px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $gray-7;
    }
    &__checks {
      width: auto;
      white-space: nowrap;
      font-size: 16px;
      line-height: 24px;
    }
    &__id {
      text-align: center;
      width: 178px;
      font-size: 16px;
      line-height: 24px;
      color: $gray-7;
    }
    &__actions {
      display: flex;
      flex-direction: row;
      & > svg {
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }
    &__edit {
      margin-right: 16px;
    }
  }
  &__create {
    &__btn {
      height: 40px;
      margin-top: 24px;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__created {
    &__text {
      font-size: 14px;
      line-height: 22px;
      color: $gray-8;
    }
    &__quotes {
      margin-top: 8px;
    }
    &__quote {
      padding-left: 16px;
      border-left: 1px solid $gray-5;
      margin-right: 8px;
      &:not(:first-child) {
        margin-top: 24px;
      }
      &__title {
        font-size: 16px;
        line-height: 24px;
      }
      &__text {
        margin-top: 4px;
        font-size: 16px;
        line-height: 22px;
        color: $gray-8;
      }
      &__copy {
        margin: 0;
        margin-left: 8px;
        padding: 0;
        top: 2px;
        height: auto;
        width: auto;
        display: inline-flex;
      }
    }
    &__btn {
      height: 40px;
      margin-top: 24px;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .api-keys {
    &__header {
      width: 100%;
      padding: 0 8px;
      &__text {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 16px;
      }
    }
    &__create-key {
      width: 100%;
    }
    &__item {
      padding: 20px 12px 16px 16px;
      &__datetime {
        margin-right: 8px;
        width: 77px;
      }
      &__date {
        font-size: 12px;
        line-height: 16px;
      }
      &__time {
        margin-top: 2px;
        font-size: 12px;
        line-height: 16px;
      }
      &__naming {
        width: 100%;
      }
      &__name {
        font-size: 14px;
        line-height: 22px;
      }
      &__public_id {
        font-size: 14px;
        line-height: 22px;
      }
      &__checks {
        width: auto;
        font-size: 12px;
        line-height: 16px;
        color: $pink;
      }
      &__menu {
        transform: rotate(90deg);
        &__trigger {
          display: inline-block;
          width: 100%;
          svg, path {
            fill: $gray-8
          }
        }
      }
    }
    &__created {

      &__quote {
        border-left: none;
        padding-left: 0;
        &__text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          span {
            display: block;
            width: calc(100% - 26px);
          }
        }
      }
    }
  }
}