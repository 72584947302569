.assets-note {
  display: flex;
  margin-top: 40px;
  padding: 12px 12px 12px 10px;
  width: 244px;
  background: #FFFFFF;
  border: 1px solid $gray-3;
  border-radius: 4px;
  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
  &__text {
    margin-left: 6px;
    font-size: 10px;
    line-height: 14px;
    color: $gray-8;
  }
}
