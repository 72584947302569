
/* Safari */
@keyframes spin {
  0% { -webkit-transform:translate(-50%, -50%)  rotate(0deg) }
  100% { -webkit-transform:translate(-50%, -50%)  rotate(360deg)}
}


@keyframes spin {
  0% { transform:translate(-50%, -50%)  rotate(0deg) }
  100% { transform:translate(-50%, -50%)  rotate(360deg)}
}
