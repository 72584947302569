.dashboard-assets {
  width: 384px;
  height: 536px;
  & .dashboard-card__title {
    padding-bottom: 28px;
  }
  @include DashboardCup;
  &__cap {
    &__content {
      top: 193px;
    }
  }
  &-list {
    padding: 0 7px;
  }
  &-item:not(:first-child) {
    border-top: 1px solid $gray-3;
  }
  &-item {
    height: 58px;
    align-items: center;
    padding: 13px 16px 13px 16px;
    display: flex;
    justify-content: space-between;

    &__crypto {
      display: flex;
      align-items: center;
      .hint-content {
        align-self: auto;
      }
    }

    &__name {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 155px;
      white-space: nowrap;
      margin-left: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    &__amounts {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
    &__amount {
      max-width: 133px;
    }
    &__value_rub {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $gray-7;
    }
  }
  &__all-btn {
    height: 48px;
    padding: 11px;
    width: 100%;
    overflow: hidden;

    background: $gray-1;
    color: $gray-6;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    border: none;
    border-top: 1px solid $gray-3;
    border-radius: 0 0 8px 8px;
    &:hover {
      background: $gray-light;
    }
    &.active {
      cursor: pointer;
      color: $gray-8;
    }
  }
  &-modal {
    & .header-modal {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    & .default-modal__content {
      padding: 0;
    }

    & .default-modal__form{
      overflow: scroll;
      height: 540px;
    }
  }
  &-skeleton {
    padding: 8px 16px 10px 16px;
  }
}

@media screen and (max-width: $max-md-media) {
  .dashboard-assets {
    width: 100%;
    height: auto;
    &-list {
      padding: 0 40px;
    }
  }
}
@media screen and (max-width: $max-sm-media) {
  .dashboard-assets {
    & .dashboard-card__title {
      padding-bottom: 16px;
    }
    &-list {
      padding: 0;
      background: #FFFFFF;
    }
    &__all-btn {
      background: transparent;
      color: $main;
      text-decoration: underline;
      &.active {
        color: $main;
      }
    }
    &__cap {
      &__content {
        top: 67px;
      }
    }
    &-modal {
      & .default-modal__form{
        padding: 8px 0;
        height: 450px;
      }
    }
  }
}
@media screen and (max-width: $sub-sm-media) {
  .dashboard-assets {
    &-item {
      &__name {
        width: 120px;
      }
    }
  }
}
@media screen and (max-width: $md-xs-media) {
  .dashboard-assets {
    &-item {
      &__name {
        width: 90px;
      }
    }
  }
}
