.transaction-add {
  &-item {
    padding: 8px 10px;
    width: 100%;
    &__title {
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $gray-9;
    }
    &__text {
      width: 332px;
      font-size: 14px;
      line-height: 18px;
      color: $gray-8;
    }
  }
  &__btn {
    margin: 8px 0 24px 0;
    width: 100%;
    height: 40px;
  }
  &__note {
    display: flex;
    svg {
      width: 24px;
      height: 24px;
    }
    &-text {
      margin-left: 16px;
      font-size: 14px;
      line-height: 18px;
      color: $gray-7;
    }
  }
  &__modal {
    .default-modal__content {
      padding: 15px 80px 32px 80px;
    }
  }
}

.transactionAddItemActive {
  background: $gray-light;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
