
@keyframes MoveUpDownNext {
  0%, 100% {
    bottom: -40px;
  }
  50% {
    bottom: -50px;
  }
}

.upgrade-plan {
  position: relative;
  background: none;
  box-shadow: none;
  padding: 0;
  width: 100% !important;
  height: 100%;
  overflow: hidden;
  &-note {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    width: 588px;
    background: #FFFFFF;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 40px;

    &__content {
      width: 100%;
      position: relative;
      padding: 0 78px 44px 78px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      text-align: center;
      border-radius: 40px;
    }
    &__title {
      margin-top: 110px;
      margin-bottom: 24px;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }
    &__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    &__close {
      position: absolute;
      @include FlexCenter;
      right: 8px;
      top: 12px;
      cursor: pointer;
      width: 40px;
      height: 40px;
    }
    &__imgs {
      &__blue-polygon {
        position: absolute;
        top: 0;
        right: 87px;
      }
      &__purple-opacity-group {
        position: absolute;
        width: 133px;
        height: 233px;
        top: -7px;
        left: 0;
      }
      &__purple-group {
        position: absolute;
        right: 0;
        top: 100px;
      }
      &__purple-vector {
        position: absolute;
        left: 243.01px;
        top: 12.37px;
      }
      &__blue-vector {
        position: absolute;
        left: 0;
        top: 145px;
      }
    }
    &__btn {
      height: 40px;
      margin-top: 40px;
    }
  }
  &-next {
    position: absolute;
    cursor: pointer;
    bottom: -43px;
    left: 50%;
    transform: translateX(-50%);
    animation: MoveUpDownNext 1.7s linear infinite;
  }
  &-pricing {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 1230px;
    margin: 23vh auto;
    &.show-header {
      margin-top: 15vh;
    }
    &__close {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $main;
      z-index: 1;
      svg {
        cursor: pointer;
        height: 16px;
        width: 16px;
      }
      svg, path {
        fill: $gray-1
      }
    }
  }
  &-drawer {
    &__content {
      padding: 16px;
    }
    &__header {
      padding-top: 22px;
      height: auto;
      & .drawer-title {
        padding-bottom: 12px;
      }
      & .pricing__header {
        padding-bottom: 8px;
      }
      & .pricing__switch__text {
        color: $gray-8;
      }
    }
  }
  &.show-header {
    .ant-modal-content {
      height: auto;
    }
  }
  .ant-modal-content {
    height: 100%;
    background: none;
    box-shadow: none;
  }
}

@media screen and (max-width: $pricing-changes) {
  .upgrade-plan {
    &.show-header {
      overflow: visible;
    }
    &-pricing {
      &__close {
        right: calc(50% - 260px);
      }
    }
  }
}

@media screen and (max-height: $max-md-media) {
  .upgrade-plan-pricing.show-header {
    margin-top: 80px;
  }
}

@media screen and (max-height: $more-md-media) {
  .upgrade-plan.show-header {
    overflow: visible;
  }
}

@media screen and (max-width: $max-sm-media) {
  .upgrade-plan {
    &-note {
      width: 328px;
      border-radius: 24px;
      &__content {
        width: 100%;
        padding: 0 24px 24px 24px;
        border-radius: 24px;
      }
      &__title {
        margin-top: 152px;
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 28px;
      }
      &__imgs {
        &__blue-polygon {
          position: absolute;
          top: 0;
          left: 154px;
          width: 130px;
        }
        &__purple-group {
          position: absolute;
          right: 0;
          top: 194px;
          height: 125px;
        }
        &__purple-vector-mobile {
          position: absolute;
          left: 122px;
          top: 24px;
        }
        &__purple-opacity-group-mobile {
          width: 70px;
          height: 140px;
          position: absolute;
          left: 0;
          top: 8px;
        }
      }
    }
    &-pricing {
      &__close {
        display: none;
      }
    }
  }
}
