.confirm-code {
  @include FlexCenter;
  flex-direction: column;
  &__text {
    text-align: center;
    color: $gray-9;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    width: 100%;
    p {
      word-wrap: break-word;
      color: $gray-7;
    }
  }
  &__input {
    width: 300px;
    margin-bottom: 40px;
    height: 56px;
    font-size: 20px;
    line-height: 24px;
    color: $gray-9;
    letter-spacing: 11px;
    ::placeholder {
      letter-spacing: 11px;
    }
  }
  .code-validate {
    text-align: center;
  }
}
