.public-404 {
  z-index: 1;
  background: $gray-1;
  min-height: 100%;
  padding-top: 358px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  &__okman-big {
    position: absolute;
    left: 10px;
    bottom: 100%;
    width: 195px;
    height: 197px;
  }
  &__okman-small {
    position: absolute;
    top: calc(100% + 104px);
    left: 100%;
    width: 110.56px;
    height: 111.7px;
  }
  &__container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 996px;
    margin: 0 auto;
  }
  &__text {
    width: 100%;
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    color: $gray-8;
    margin-bottom: 64px;
    text-align: center;
  }
  &__redirect {
    font-size: 16px;
    line-height: 24px;
    padding: 28px 79px;
    border-radius: 4px;
  }
}

$center-public-404-container: 964px;
@media screen and (max-height: $center-public-404-container) {
  .public-404 {
    padding-top: 0;
    &__container {
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .public-404 {
    &.container {
      width: 100%;
    }
    &__okman-small {
      right: 0;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .public-404 {
    padding: 0 16px;
    &__okman-big {
      width: 115px;
      height: 115px;
      left: 16px;
      top: auto;
      bottom: 172px;
    }
    &__okman-small {
      display: none;
    }
    &__container {
      position: relative;
      bottom: 88px;
      height: 100%;
      left: auto;
      transform: none;
      top: auto;
    }
    &__text {
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      text-align: center;
    }
    &__redirect {
      margin-top: auto;
      width: 100%;
    }
  }
}
