.breadcrumb {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  &-item {
    &:hover {
      text-decoration-line: underline;
    }
  }
}
.breadcrumbItemActive {
  color: $main;
}
