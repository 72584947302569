.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: $gray-9;
}

.document-ready-title {
  display: inline;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $gray-8;
}