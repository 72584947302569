.asset-amount {
  max-width: 146px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  sup {
    margin-left: 1px;
  }
}

@media screen and (max-width: $max-sm-media) {
  .asset-amount {
    max-width: 130px;
    font-size: 14px;
    line-height: 22px;
  }
}
