.transactions-filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .select {
    margin-right: 16px;
    width: 180px;
  }
  .date-picker {
    width: 228px;
  }
  .select-assets, .select-type {
    width: 180px;
  }
  .select-currency {
    width: 156px;
  }
  &__btn {
    margin-left: auto;
    background: inherit;
    color: $main;
    border: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
  }
  &__add-transaction {
    cursor: pointer;
    margin-left: 12px;
    display: flex;
    align-items: center;
    color: $main;
    font-size: 14px;
    line-height: 24px;
    text-decoration: underline;

    .plus-icon {
      margin-right: 8px;
      width: 22px;
      height: 22px;
    }

    &:hover .plus-icon > path {
      fill: $blue-2;
    }
    &:hover {
      color: $blue-2;
    }

  }
  &__search {
    width: 188px;
    margin-left: auto;
  }
}
