.account-beta-badge {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 24px;
  height: 16px;
  background: $gray-1;
  border-radius: 3px;
  text-align: center;
  font-style: italic;
  font-size: 10px;
  line-height: 16px;
  color: $pink;
}
