@keyframes load1 {
  0%,
  80%,
  100% {
    width: 100%;
  }
  40% {
    opacity: 0.1;
    width: 1%;
  }
}

.sankey-chart {
  &.revert {
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
    -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
    -o-transform: matrix(-1, 0, 0, 1, 0, 0);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  &-loading {
    top: 0;
    position: absolute;
    width: 400px;
    height: 325px;
    -webkit-transform: matrix(-1, 0, 0, 1, 0, 0) translateY(-50%);
    -moz-transform: matrix(-1, 0, 0, 1, 0, 0) translateY(-50%);
    -o-transform: matrix(-1, 0, 0, 1, 0, 0) translateY(-50%);
    transform: matrix(-1, 0, 0, 1, 0, 0) translateY(-50%);
    &.revert {
      -webkit-transform: matrix(-1, 0, 0, 1, 0, 0) translateY(-50%);
      -moz-transform: matrix(-1, 0, 0, 1, 0, 0) translateY(-50%);
      -o-transform: matrix(-1, 0, 0, 1, 0, 0) translateY(-50%);
      transform: matrix(-1, 0, 0, 1, 0, 0) translateY(-50%);
    }
    &__column {
      position: absolute;
      right: 0;
      top: 53px;
      width: 12px;
      height: 250px;
      background-color: $pink;
    }
    &__vectors {
      width: 400px;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-animation: load1 2s infinite ease-in-out; /* Safari */
      animation: load1 3s infinite ease-in-out;
    }
    .vector {
      width: 100%;
      position: absolute;
      right: 0;
    }
    &__vector {
      top: 9px;
      height: 122.71px;
    }
    &__vector-1 {
      top: 101px;
      height: 109.96px;
    }
    &__vector-2 {
      top: 192px;
      height: 47.8px;
    }
    &__vector-3 {
      top: 233px;
      height: 22.83px;
    }
    &__vector-4 {
      top: 255px;
      height: 22.3px;
    }
    &__vector-5 {
      top: 271px;
      height: 33.8px;
    }
    &__vector-6 {
      top: 286px;
      height: 46.73px;
    }
  }
  & > div {
    overflow: visible !important;
    & > div {
      overflow: visible !important;
    }
  }
}

.sankey-loading {
  width: fit-content;
  margin-bottom: 40px;
  &-wrapper {
    display: flex;
    flex-direction: column;
  }
}