.sync {
  cursor: pointer;
  float: right;
  position: relative;

  &, & svg {
    width: 24px;
    height: 24px;
  }

  svg {
    fill: $red-6;
    position: absolute;
  }

  & .sync-loader {
    top: 50%;
    right: -50%;
  }
}
