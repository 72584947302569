.share-link {
  &.default-btn.transparent {
    color: $gray-8;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    border: none;
    svg, path {
      fill: $gray-8;
    }
  }
  &__modal {
      .default-modal__content {
        padding: 40px 60px 60px 60px;
      }
}
  &__copy-link {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    &__input {
      width: 100%;
      margin-right: 16px;
      height: 40px;
    }
    &__btn {
      width: 160px;
      height: 40px;
    }
  }
  &__icons {
    max-width: 516px;
    display: flex;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    &__wrapper {
      position: relative;
    }
    & > * {
      min-width: 60px;
      min-height: 60px;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
    &__prev, &__next {
      position: absolute;
      cursor: pointer;
      top: 50%;
      width: 24px;
      height: 24px;
      transform: translateY(-50%);
      svg {
        width: 24px;
        height: 24px;
      }
    }
    &__prev {
      left: -40px;
    }
    &__next {
      right: -40px;
      svg {
        transform: rotate(180deg);
      }
    }
  }
}