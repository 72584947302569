.crypto-icon {
  @include NoSelect;
  @include FlexCenter;
  height: 32px;
  width: 32px;

  &.btcLogo {
    background: $btc;
  }
  &.not-listed-in-catalog {
    background-color: $main;
    color: $gray-1;
    border-radius: 50%;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    &.lng-4 {
      font-size: 10px;
    }
    &.lng-3 {
      font-size: 12px;
    }
  }
}

.isExternalCryptoIcon {
  opacity: 0.2;
}
