.dashboard-card {
  position: relative;
  width: auto;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid $gray-4;
  background: $gray-1;
  &.empty {
    border: none;
    border-radius: 0;
    background: none;
    box-shadow: none;
  }
  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    padding: 24px 24px 24px 40px;
  }
  &__content {
    width: auto;
  }

  @include DashboardCup;
  &__cap {
    &__content {
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }
  }
  & table {
    width: 100%;

    & tbody {
      width: 100%;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .dashboard-card {
    width: 100%;
  }
}
@media screen and (max-width: $max-sm-media) {
  .dashboard-card {
    margin-bottom: 24px;
    &__title {
      padding: 16px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
