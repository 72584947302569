.ant-select-selector {
  border: none !important;
  padding-top: 1px !important;
}

.ant-select-arrow {
  padding-top: 1px !important;
}

.ant-select-arrow svg {
  fill: $main;
}

