.public {
  &.main {
    display: flex;
  }
  &-transactions {
    .transactions-filter .select-currency {
      width: 230px;
    }
    .transactions-filter .select-type {
      width: 232px;
    }
    .transactions-filter .date-picker {
      width: 282px;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .public {
    &.main {
      padding: 0;
    }
    &-transactions {
      .transactions-filter .date-picker {
        margin-right: 0;
        width: 226px;
      }
      .transactions-header__counter {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .public {
    &.main {
      padding: 0;
    }
    &-transactions-page {
      &.main {
        padding-bottom: 88px;
      }
    }
  }
}
