@media screen and (max-width: $max-lg-media) {
  .assets-single {
    &__sub_header {
      &_wrapper {
        padding-right: 24px;
      }
    }
    &__total-balance {
      &_wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-top: 4px;
      }
      &_currency {
        margin-top: -3px;
      }
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .assets-single {
    &__wrapper {
      border-radius: 8px;
    }
    &__content_wrapper {
      padding-right: 40px;
    }
    &-transactions {
      &__list {
        width: 720px;
        margin: auto;
      }
    }
    &-sync-notes {
      padding-top: 29px;
    }
    &-public-wallet {
      width: 720px;
    }
  }
}
@media screen and (max-width: $max-sm-media) {
  .assets-single {
    &-wrapper {
      min-height: auto;
      border-radius: 0;
      box-shadow: none;
      position: relative;
      border: none;
      background: none;
    }
    &__header {
      &_wrapper {
        padding: 0;
      }
      &__risk {
        width: auto;
        margin-top: -3px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 22px;
        text-decoration-line: none;
        &__icon {
          margin-right: 2px;
          width: 15px;
          height: 15px;
          text-decoration-line: none;
        }
      }
      &_menu {
        & .bn-dropdown__overlay {
          width: 220px;
          top: 45px;
          left: -180px;
        }
        & > li > span {
          font-size: 14px;
          margin: 0 4px;
        }
      }
    }
    &__header-mobile {
      &_wrapper {
        //padding: 0 8px;
        //display: flex;
        //justify-items: center;
        //align-items: center;
        //width: 100%;
        display: none;
      }
      &_icon {
        position: relative;
        & > img {
          width: 40px;
          height: 40px;
        }
        & .account-beta-badge {
          top: 0;
          right: -11px;
        }
      }
      &_data {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-left: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &__total-cost {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &__risk {
        display: flex;
        align-items: center;
        margin-left: auto;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-decoration-line: underline;
        color: $main;
        white-space: nowrap;
        &:hover {
          color: $blue-2;
          text-decoration-line: underline;
        }
        &__icon {
          margin-right: 2px;
          width: 15px;
          height: 15px;
        }
        &.isPublic {
          text-decoration-line: none;
          color: $gray-9;
          &.ok {
            color: $green-3;
          }
          &.warning {
            color: $red-3;
          }
          &.error {
            color: $red-6;
          }
        }
      }
      &__transactions {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $gray-7;
      }
      &__sync-action {
        width: 100%;
        & > .sync {
          float: right;
        }
      }
    }
    &__content {
      &_wrapper {
        position: relative;
        background: none;
        margin-top: 10px;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        flex-direction: column-reverse;
        gap: 40px;
      }
    }
    &__total-balance {
      &_rub {
        & span:first-child {
          font-size: 14px;
          line-height: 22px;
        }
        & span:last-child {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    &-transactions {
      &__title {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
      }
      &__list {
        width: 100%;
      }
      &__go-to {
        padding: 0;
        color: $main;
        border: none;
        background: none;
        text-decoration: underline;
      }
    }
    &-sync-note {
      width: 100%;
    }
    &-public-wallet {
      margin: 0;
      position: absolute;
      right: 3px;
      top: -8px;
      //background: $gray-2;
      //border: 1px solid $gray-3;
      border-radius: 2px;
      width: auto;
      &.container.mob-p-8 {
        padding: 8px 16px 8px 16px;
      }
      .share-link {
        width: 20px;
        height: 20px;
        margin-left: 8px;
        margin-right: 0;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .bn-switch {
        line-height: 16px;
      }
      .bn-switch__label {
        color: $gray-8;
        font-size: 12px;
        line-height: 16px;
        margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: $md-xs-media) {
  .assets-single {
    &__total-balance {
      &_wrapper:not(.risk) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }
      &_currency:not(.risk) {
        margin-top: 16px;
      }
    }
  }
}

@media screen and (max-width: $x-media) {
  .assets-single {
    &-wrapper {
      margin-top: 0;
    }

    &__header-mobile {
      &_wrapper {
        display: none;
      }
    }

    &__content {
      &_wrapper {
        margin-top: 16px;
        display: flex;
        flex-direction: column-reverse;
        gap: 40px;
      }
    }

    &__total-balance {
      &_rub {
        background: none;
        border: none;
      }
    }
  }
}
@media screen and (max-width: $md-media) {
  .assets-single {
    &__sync-account {
      margin-right: 0;
      position: absolute;
      bottom: -2px;
      right: auto;
      left: 27px;
      top: auto;
    }
    &-sync-notes {
      display: none;
    }
}}
