.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background: $main;
  border: 1px solid $main;
  border-radius: 2px;
}

.ant-checkbox-checked::after {
  border: 1px solid $main;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $main;
}
