.bind-modal {
  width: 100%;
  .ant-modal-close {
    display: none;
  }
  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 14px 32px 14px;
    .label {
      color: $gray-9;
      font-size: 14px;
      line-height: 18px;
    }
    &-input-wrapper {
      display: flex;
      flex-direction: column;
      //height: 106px;
      width: 100%;
      max-width: 384px;
      .field-wrapper:not(:first-child) {
        margin-top: 16px;
      }
    }
    &-input,
    &-btn {
      max-width: 384px;
      width: 100%;
      height: 40px;
    }
    &-btn {
      margin-top: 40px;
    }
  }
}
