.mobile-tab-menu {
  width: 100%;
  z-index: 1001;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 8px 8px 0 0;
  background: #F3F4F9;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
  &__item {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(2) {
      svg {
        width: 21px;
        height: 21px;
      }
    }
    &:nth-child(4) {
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
  &__link {
    svg {
      width: 24px;
      height: 24px;

      path {
        fill: $gray-8;
      }
    }
    &.active {
      svg path {
        fill: $pink;
      }
    }
  }
  &__additional {
    width: 48px;
    height: 48px;
    background: $gray-4;
    svg path {
      fill: $gray-8;
      @include Transition;
    }
    &__overlay {
      width: 272px;
      padding: 0;
      background: $gray-2;
      top: auto;
      bottom: 64px;
      right: -42px;
      color: $gray-8;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
      border-radius: 8px 0 0 0;
      overflow: hidden;
      transition: all .3s ease-in-out;

      &.active {
        transform: translateX(-42px);
      }
      &__link {
        display: flex;
        align-items: center;
        padding: 12px 24px;
        height: 48px;
        font-size: 16px;
        line-height: 24px;
        border-bottom: 1px solid #E8E9F0;
        &:hover {
          opacity: 0.8;
        }
        &.active {
          color: $main;
          background: $gray-5;
        }
        svg {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
      }
    }
    &.active {
      svg path {
        fill: $pink;
      }
    }
  }
  &__drawer {
    .drawer-content {
      background-color: $gray-1;
      height: 100%;
    }
    &__content {
      display: flex;
      align-items: center;
      flex-direction: column;
      header {
        background: $gray-light;
        width: 100%;
      }
    }
    &__header {
      display: flex;
      margin-right: 17px;
      gap: 16px;
      padding: 24px 0 17px 40px;
    }
    &__logo {
      width: 22px;
      height: 21px;
      flex-shrink: 0;
    }
    &__username {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $gray-7;
      margin-bottom: 12px;
    }
    &__current-plan {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $gray-9;
      margin-bottom: 7px;
      cursor: pointer;
      span:first-child {
        text-decoration: none;
        color: $gray-9;
        margin-left: 4px;
      }
      span {
        text-decoration: underline;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $pink;
      }
    }
    &__links {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 24px 16px 0 16px;
    }
    &__link {
      display: flex;
      align-items: center;
      padding: 12px 24px;
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 22px;
      svg {
        margin-right: 16px;
        width: 24px;
        height: 24px;
      }
      svg path {
        fill: $gray-9
      }
    }
  }
}
