@media screen and (max-width: $max-sm-media) {
  .password-item {
    &_form {
      margin-left: 16px;
    }
    &_input {
      height: 40px;
      margin-top: 8px;
      width: 100%;
    }
    &_new {
      flex-direction: column;
      margin: 14px 0 24px 0;
      &--repeat {
        margin: 16px 0 0 0;
      }
    }
    &_btn {
      width: auto;
    }
  }
}
