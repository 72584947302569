.tax-tx-type {
  &__step-two {
    padding: 40px 0;
    &__row {
      width: 100%;
      padding-bottom: 32px;
      &__title {
        padding-bottom: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        &.types {
          padding-bottom: 24px;
        }
      }
    }
    .field-wrapper:not(:first-child) {
      margin-top: 0;
    }
    &__year, &__cost-basis, &__time-zone {
      width: 282px;
    }
    .transactions-edit__currency-item-wrapper {
      height: 32px;
      background: $gray-1;
      border: 1px solid $gray-5;
    }
    .transactions-edit__currency {
      height: 32px;
      margin-left: 0;
      width: 282px;
    }
    .transactions-edit__currency-item-input {
      background: $gray-1;
      width: 100%;
      height: 32px;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .tax-tx-type {
    &__step-two {
      padding: 16px 24px !important;

      &__year, &__cost-basis, &__time-zone {
        width: 100%;
      }
      .transactions-edit__currency {
        width: 100%;
      }
    }
  }
}
