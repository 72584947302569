.dashboard, .trading-insights, .public-account {
  justify-content: space-between;
  &-desktop {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
  }
  &-mobile {
    display: flex;
    flex-direction: column;
  }
  &-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  & .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  & .col {
    display: flex;
    flex-direction: column;
  }
  &-left {
    width: 792px;
  }
  &-right {
    width: 384px;
  }
  &-onboarding-btn {
    padding: 5px 16px 5px 20px;
    position: absolute;
    cursor: pointer;
    border: none;
    top: 40px;
    right: -87px;
    background: $pink;
    z-index: 1;
    border-radius: 40px 0 0 40px;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $gray-1;
    @include Transition;
    span {
      margin-right: 12px;
    }

    &.ru {
      right: -118px;
    }
    &:hover {
      right: 0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
    &.show {
      z-index: 1000;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  }
  &-page {
    position: relative;
    overflow-x: hidden;
  }
}
.public-account {
  &-page {
    padding-bottom: 88px;
  }
  .dashboard-diagram-selector {
    margin-top: 16px;
  }
  .dashboard-diagram {
    border-radius: 8px;
  }
}

@mixin DashboardCup {
  &__cap {
    @include Transition;
    position: absolute;
    opacity: 0;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(116, 138, 218, 0.6);
    backdrop-filter: blur(2.5px);
    border-radius: 8px;
    border: 2px solid $main;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    &__content {
      position: absolute;
      right: 24px;
      text-align: center;
      display: flex;
      align-items: end;
      svg {
        width: 24px;
        height: 24px;
        transform: rotate(180deg);
      }
      svg path {
        fill: $gray-1;
      }
    }
    &__text {
      white-space: nowrap;
      margin-right: 16px;
      font-family: 'Roboto', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $gray-1;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .trading-insights, .dashboard, .public-account {
    margin-top: 24px;
    & .col {
      width: 100%;
    }
    & .row {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: $max-sm-media) {
  .dashboard, .trading-insights, .public-account {
    margin-top: 40px;

    & .row {
      padding: 0 8px;
    }
    &-page.main {
      padding-top: 0;
    }
    &-top {
      flex-direction: column;
    }
  }
  .public-account {
    margin-top: 0;
    .dashboard-diagram__variable-radius-pie {
      margin: 0 auto;
    }
    .dashboard-diagram-selector {
      margin-top: 30px;
    }
    .dashboard-diagram-assets {
      width: auto;
    }
  }
  .trading-insights {
    margin-top: 0;
  }
}
