.tags-multiplier {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__input {
    position: relative;
    height: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    input {
      width: 100%;
      padding: 10px;
      border: 1px solid $gray-5;
      border-right: none;
      border-radius: 2px 0 0 2px;
      background: $gray-1;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $gray-9;
      &::placeholder {
        color: $gray-6;
        opacity: 1;
      }
    }
    input:focus, input:hover {
      outline: none;
    }
    button {
      height: 40px;
      font-weight: 400;
      font-size: 14px;
      padding: 10px 16px;
      border: 1px solid $gray-5;
      border-radius: 0 2px 2px 0;
      color: $gray-9;
      &:disabled {
        background-color: $gray-5;
        color: $gray-1;
      }
      &:disabled:hover {
        background-color: $gray-5 !important;
        color: $gray-1;
      }
    }
    .ant-btn-primary:hover, .ant-btn-primary:focus {
      border-color: $gray-5;
    }
  }
  &__counter {
    position: absolute;
    right: 12px;
    top: -21px;
    letter-spacing: 3px;
    font-weight: 400;
    font-size: 12px;
    color: $gray-7;
    line-height: 144%;
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
  }
  &__tag {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 8px;
    margin-top: 8px;
    padding: 3px 4px 3px 8px;
    background: #EEEFF3;
    border-radius: 16px;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $main;
    .close {
      position: relative;
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin-left: 8px;
      background: $gray-5;
      border-radius: 20px;
      @include Transition;
    }
    .close:hover {
      opacity: 0.6;
    }
    .close:before, .close:after {
      position: absolute;
      right: 7.5px;
      bottom: 4px;
      content: ' ';
      height: 8px;
      width: 1px;
      background-color: $gray-1;
    }
    .close:before {
      transform: rotate(45deg);
    }
    .close:after {
      transform: rotate(-45deg);
    }
  }
  &__menu {
    max-height: 200px;
    overflow-y: scroll;
    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
      padding: 5px 12px;
    }
  }
}