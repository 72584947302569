.sync-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  & .sync-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13px;
    height: 13px;
  }
  & .error {
    position: absolute;
    width: 13px;
    height: 13px;
    &, path {
      fill: $red-6;
    }
  }
}