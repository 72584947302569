
.dashboard-diagram {
  width: 588px;
  height: 312px;
  border-radius: 8px 32px 8px 8px;

  & .dashboard-card__content {
    display: flex;
    padding: 0 24px 24px 24px;
  }
  & .dashboard-card__title {
    padding-bottom: 4px;
  }

  &-selector {
    margin-left: 31px;
  }

  &-radio {
    margin-bottom: 24px;
    .ant-radio-button-wrapper {
      color: $gray-8;
      font-size: 14px;
      height: 32px;
    }
    .radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: $gray-9;
      border-color: $gray-4;
      background-color: $gray-4;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: $gray-4;
    }
    .radio .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
      color: $gray-9;
    }
  }
  &-assets {
    font-weight: 400;
    line-height: 22px;
    font-size: 14px;
    color: $gray-9;

    tr:not(:last-child) {
      td {
        padding-bottom: 16px;
      }
    }

    &__name {
      padding-left: 14px;
      padding-right: 20px;
      position: relative;

      &:after {
        right: 0;
        top: 7px;
        position: absolute;
        content: "";
        width: 1px;
        height: 12px;
        border-right: 1px solid $gray-5;
      }
    }
    &__ratio {
      padding-left: 20px;
      padding-right: 16px;
      color: $gray-7;
      text-align: end;
    }
  }
}
.dashboard-diagram__variable-radius-pie {
  padding-top: 10px;
  width: 200px;
  height: 200px;
}

@media screen and (max-width: $max-md-media) {
  .dashboard-diagram {
    width: 100%;
  }
}
@media screen and (max-width: $max-sm-media) {
  .dashboard-diagram {
    width: 100%;
    height: auto;
    border-radius: 8px;

    &-selector {
      margin-left: 5px;
    }
    & .dashboard-card__content {
      display: flex;
      flex-direction: column;
      padding: 0 16px 42px 16px;
    }
    & .dashboard-card__title {
      padding: 16px 16px 6px 16px;
    }

  }
  .dashboard-diagram__variable-radius-pie {
    padding-top: 0;
    margin-bottom: 24px;
  }
}
