.crypto-assets-list {
  &:not(:first-child) {
    margin-top: 24px;
  }
  width: 482px;

  &_title {
    margin-left: 12px;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 22px;
    color: $gray-7;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &_transactions {
    font-weight: 400;
    margin-right: 16px;
    font-size: 14px;
    line-height: 18px;
    color: $main;
    &:hover {
      color: $blue-2;
    }
    &.underline {
      text-decoration-line: underline;
    }

  }

  &_items {
    & > div {
      border-bottom: 1px solid $gray-3;
    }
    & div:last-child {
      border-bottom: none;
    }
  }
  &__show-more {
    padding: 11px;
    width: 100%;

    background: $gray-1;
    cursor: pointer;
    color: $gray-7;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    border: none;
    border-top: 1px solid $gray-3;
    border-radius: 0 0 8px 8px;
    &:hover {
      background: $gray-light;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .crypto-assets-list {
    width: 344px;
  }
}

@media screen and (max-width: $max-sm-media) {
  .crypto-assets-subaccounts {
    width: 100%;
  }
  .crypto-assets-list {
    width: 100%;
    background: none;
    &_title {
      margin-left: 16px;
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $gray-8;
    }
    &_transactions {
      margin-right: 0;
    }
    &_items {
      background-color: $gray-1;
      & .crypto-item_wrapper:last-child {
        border-bottom: 1px solid $gray-3;
      }
    }
  }
}
