@media screen and (min-width: $x-media) {
  .stickyTop {
    @include Transition;
  }
}

@media screen and (max-width: $max-md-media) {
  .transactions-header {
    &__content {
      padding: 24px 0 18px 0;
    }
    &__counter {
      margin-left: auto;
      color: $gray-7;
    }
  }
}
