@media screen and (max-width: $max-md-media) {
  .transactions-item {
    width: 720px;
    position: relative;
    &__additional-info {
      &__btns {
        display: flex;
        flex-direction: row;
        position: absolute;
        right: 56px;
        top: -5px;
        z-index: 2;
        & > div {
          cursor: pointer;
        }
        &__note {
          width: 20px;
          height: 20px;
          svg {
            width: 20px;
            height: 20px;
          }
          margin-left: 8px;
          svg, circle {
            fill: $pink;
          }
        }
        &__tag {
          background: $pink;
          width: 20px;
          height: 20px;
          span {
            color: $gray-4;
          }
        }
      }
    }
    &__main {
      min-width: 720px;
      padding: 11px 40px 11px 16px;
      border-top: none;
    }
    &-left {
      margin: 0;
      &__info {
        margin-left: 16px;
        min-width: 128px;
        max-width: 128px;
      }
    }
    &-center {
      &__operation {
        min-width: 230px;
      }
      &__arrow {
        display: none;
      }
      &__operation:last-child {
        margin-left: 16px;
      }
    }
    &-vector {
      display: none;
    }
    &.dashboard-transaction {
      .transactions-item__main {
        border-left: 1px solid $gray-4;
      }
    }
  }
  .transactionsItemNeedDetails, .reportTransactionsItemNeedDetails {
    border: 1px solid $gray-5;
    border-top: none !important;
  }
}

@media screen and (max-width: $max-sm-media) {
  .transactions-item {
    width: 100%;
    border-top: 1px solid $gray-3;
    border-bottom: 1px solid $gray-3;
    margin-bottom: 16px;

    &:first-child {
      .transactions-item__main {
        border-radius: 0;
        border-top: none;
      }
    }
    &:last-child {
      .transactions-item__main {
        border-radius: 0;
      }
    }
    &:only-child {
      .transactions-item__main {
        border-radius: 0;
      }
    }
    &__main {
      min-width: 100%;
      width: 100%;
      border: none;
      flex-direction: column;
      height: auto;
      padding: 0;
      &__dropdown {
        svg, path {
          fill: $main;
        }
      }
    }
    &__additional-info {
      &__btns {
        right: 56px;
        top: 12px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    &__dropdown {
      line-height: 18px;
      &-name, &-name:hover {
        font-size: 14px;
        line-height: 18px;
      }
    }
    &-left {
      position: relative;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      height: 44px;
      margin-right: 0;
      padding: 5px 16px 2px 16px;
      background: $gray-1;
      z-index: 2;

      &.noBorder {
        box-shadow: 0 1.5px 2px rgba(0, 0, 0, 0.06);
      }
      &:not(.noBorder):after {
        content: "";
        position: absolute;
        margin: 0 auto;

        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 16px);
        height: 1px;
        background-color: $gray-4;
      }
      &__icon {
        width: 24px;
        height: 24px;
        svg {
          margin: auto;
          width: 24px;
          height: 24px;
        }
        &.not-listed-in-catalog {
          font-size: 11px !important;
          &.lng-4 {
            font-size: 7px !important;;
          }
          &.lng-3 {
            font-size: 9px !important;;
          }
        }
      }
      .needDetailsIcon {
        svg {
          margin-left: 8px;
          height: 22px;
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
        margin-left: 18px;
        width: 100%;
        max-width: 100%;
        &-date-wrapper {
          display: flex;
          align-items: center;
          .transactions-item__checkbox {
            width: auto;
            height: auto;
          }
        }
        &-name {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 0;
        }
        &-date {
          font-size: 12px;
          line-height: 16px;
        }
        &-sub-name {
          display: none;
        }
        &.noBtns {
          flex-direction: row;
          align-items: center;
          margin-right: 32px;
          justify-content: space-between;
        }
      }
    }
    &-center {
      padding: 5px 8px 8px 8px;
      width: 100%;
      min-width: 0;
      align-items: center;
      min-height: 77px;
      &.marginTrading {
        padding: 0 4px 4px 8px !important;
      }
      .externalOperation {
        height: 64px;
        padding: 6px 6px 6px 8px;
        .transactions-item-center__operation__content__left {
          margin-top: 0;
        }
        .transactions-item-center__operation__header {
          margin-bottom: 8px;
        }
        .transactions-item-center__operation__title {
          font-size: 12px;
          line-height: 14px;
        }
        .transactions-item-center__operation__msg {
          margin-top: 6px;
        }
        .transactions-item-center__operation__name {
          margin-top: 4px;
        }
      }
      &__operation {
        @include ScrollWithoutLine;
        width: 50%;
        max-width: 50%;
        min-width: 50%;
        height: auto;
        padding: 6px 4px 4px 8px;
        margin: 0;
        &:first-child {
          display: inline-block;
          float: left;
        }
        &:not(:first-child) {
          display: block;
          margin: 0 auto;
        }
        &.noHeader {
          display: flex !important;
          align-items: center;
        }
        &__content {
          flex-direction: column;
          &__left {
            margin-top: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
        &__header {
          height: 14px;
          margin-bottom: 8px;
        }
        &__title {
          font-size: 12px;
          line-height: 14px;
        }
        &__btns {
          position: absolute;
          top: 6px;
          right: 6px;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          &-item {
            margin-bottom: 0;
            &:first-child {
              margin-left: 4px;
            }
            width: 14px;
            height: 14px;
            svg {
              @include FlexCenter;
              width: 14px;
              height: 14px;
            }
          }
        }
        &__msg {
          display: none;
          margin-top: 6px;
          margin-left: 0;
          div {
            font-size: 12px;
            line-height: 14px;
            height: 14px;
          }
        }
        &__logo {
          margin-right: 7px;
          height: 24px;
          width: 24px;
          &.not-listed-in-catalog {
            font-size: 11px !important;
            &.lng-4 {
              font-size: 7px !important;;
            }
            &.lng-3 {
              font-size: 9px !important;;
            }
          }
        }
        &.marginTrading {
          border: none;
          padding: 0 0 0 4px;
          display: inline-block;
        }
      }
      &__arrow {
        display: none;
      }
      &__margin-trading {
        &__symbol {
          border-radius: 0 0 4px 4px;
        }
        &__position {
          margin-bottom: 5px;
        }
        &__content {
          padding: 5px 0 6px 5px;
        }
      }
      &__multiple-asset {
        &__content {
          height: 32px;
          &.showMore {
            height: 46px;
          }
        }
        &__costs {
          margin-left: 11px;
        }
        &__cost {
          font-size: 12px;
          line-height: 16px;
        }
        &__icon {
          &.crypto-icon {
            width: 20px;
            height: 20px;
          }
          &.not-listed-in-catalog {
            font-size: 9px !important;
            &.lng-4 {
              font-size: 6px !important;;
            }
            &.lng-3 {
              font-size: 7px !important;;
            }
          }
        }
        &__show-more {
          width: 20px;
          height: 20px;
          font-size: 10px;
        }
      }
    }
    &__checkbox {
      right: 16px;
    }
    &.dashboard-transaction {
      margin-bottom: 8px;

      .transactions-item__main {
        border: none;
      }
    }
    .isWithdrawal {
      background: $withdrawal;
    }
    .isDeposit {
      background: $deposit;
    }
    .isExchange {
      background: $exchange;
    }
    .isTransfer {
      background: $transfer;
    }
  }

  .transactionsItemWidthCheckbox {
    .transactions-item-left, .transactions-item-center {
      background: $gray-light;
    }
  }

  .transactionsChecked {
    .transactions-item-left, .transactions-item-center {
      background: rgba(63, 134, 226, 0.1);
    }
  }

  .reportTransactionsItemNeedDetails {
    .transactions-item-left, .transactions-item-center{
      background: $red-1;
    }
  }
}
