.crypto-item {
  &_wrapper {
    display: flex;
    flex-direction: row;
    padding: 8px 16px 8px 12px;
  }

  &_icon {
    &.crypto-icon {
      width: 26px;
      height: 26px;
      min-height: 26px;
      min-width: 26px;
      margin-top: 5px;
      margin-right: 14px;
    }
    &.not-listed-in-catalog {
      font-size: 12px !important;
      &.lng-4 {
        font-size: 8px !important;;
      }
      &.lng-3 {
        font-size: 10px !important;;
      }
    }
  }

  &_balance {
    margin-left: auto;
    text-align: right;

    &_crypto {
      text-align: right;
    }

    &_usd {
      font-size: 14px;
      line-height: 18px;
      text-align: right;
      color: $gray-7;
    }
  }

  &_name {
    margin-right: 16px;
    width: 252px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $gray-9;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &_transactions {
    font-size: 14px;
    line-height: 18px;
    color: $gray-7;
  }
}

@media screen and (max-width: $max-sm-media) {
  .crypto-item {
    &_wrapper {
      align-items: center;
      padding: 8px 16px 8px 12px;
    }
    &_icon {
      &.crypto-icon {
        width: 32px;
        height: 32px;
        margin-top: 0;
        margin-right: 8px;
      }
      &.not-listed-in-catalog {
        font-size: 14px !important;
        &.lng-4 {
          font-size: 10px !important;;
        }
        &.lng-3 {
          font-size: 12px !important;;
        }
      }
    }
    &_name {
      width: 353px;
    }
  }
}

@media screen and (max-width: $sm-media) {
  .crypto-item_name {
    width: 258px;
  }
}
@media screen and (max-width: $sub-lm-media) {
  .crypto-item_name {
    width: 202px;
  }
}
@media screen and (max-width: $sub-sm-media) {
  .crypto-item_name {
    width: 132px;
  }
}
@media screen and (max-width: $md-xs-media) {
  .crypto-item_name {
    width: 92px;
  }
}
