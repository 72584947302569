@media screen and (max-width: $max-lg-media) {
  .create-document__step-three__header {
    margin-top: 0;
  }
}

@media screen and (max-width: $max-md-media) {
  .create-document__step-three__header {
    margin-top: 16px;
  }
}

//@media screen and (max-width: $max-lg-media) {}
@media screen and (max-width: $max-sm-media) {
  .create-document__step-three {
    &__wrapper {
      padding-top: 0;
    }
    &__container {
      max-width: 100%;
      padding: 0;
    }
    &__header {
      margin-top: 0;
      margin-bottom: 8px;
      padding: 32px 24px 18px 24px;
      background: $gray-3;
      &-text {
        max-width: 100%;
        font-size: 12px;
      }
    }
    &__form {
      padding: 0 24px;
      background: inherit;
      border: none;
      border-radius: 0;
      .field-wrapper {
        max-width: 100%;
        &:not(:first-child) {
          margin-top: 16px;
        }
      }
      &-btn {
        margin-top: 40px;
        width: 100%;
        height: 40px;
      }
      &-title {
        margin: 16px 0 16px -8px;
        font-size: 16px;
        line-height: 24px;
      }
      &-line {
        margin: 0 -24px;
      }
      &-flex {
        flex-direction: column;
      }
      &--date-phone {
        margin-top: 16px;
        margin-bottom: 16px;
      }
      .oktmo-wrapper-class, .phone-wrapper-class, .passport_number-wrapper-class, .ifns-wrapper-class {
        margin-top: 16px !important;
        margin-left: 0;
        max-width: 100%;
      }
      .date-wrapper-class {
        max-width: 100%;
      }
      .inn-wrapper-class {
        margin-top: 16px !important;
      }
      .passport_series-wrapper-class {
        max-width: 100%;
      }
    }
  }
}
