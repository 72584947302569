.bn-dropdown {
  position: relative;
  &-navigation {
    position: relative;
  }
  &__appearance {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__overlay {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    position: absolute;
    z-index: 1032;
    top: 49px;
    right: 0;
    width: 282px;
    background: $gray-3;
    color: $gray-9;
    font-size: 16px;
    line-height: 22px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 2px 2px;
    transform: translateY(10px);
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease;
    &.active {
      transform: translate(0, 0);
      opacity: 1;
      pointer-events: all;
    }
  }
  &_btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__menu {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 2px 2px;
    li {
      min-width: 136px;
      padding: 10px 36px 10px 24px;
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  }
}
.ant-dropdown-menu-title-content > a:hover {
  color: $main;
}
.ant-dropdown-menu-title-content:hover {
  color:$main;
}
.bn-dropdown__menu-share-item {
  padding: 0;
  text-align: start;
  border: none !important;
  height: 100%;
  width: 100%;
}
.bn-dropdown__menu-share-item > span {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 24px;
}
.bn-dropdown__menu-share-item > span:hover{
  color:  $main;
}
.bn-dropdown__menu li:last-child {
  color:$red-7;
}
.bn-dropdown__menu li:nth-child(2) {
  padding: 0;
}
.bn-dropdown__menu li:nth-child(2) > span {
  width: 196.65px;
  height: 42px;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled{
  pointer-events: none;
  cursor: default;
}
.pdf-report-disabled {
  pointer-events: none;
  cursor: default;
}
