.copy-dropdown {
  &__text {
    @include Transition;
    cursor: pointer;
  }
  & .bn-dropdown__overlay {
    position: absolute;
    padding: 0;
    top: 15px;
    width: 200px;
    background-color: $gray-1;
    border-radius: 8px;
    box-shadow: 0px 8px 28px 8px rgba(0, 0, 0, 0.08), 0px 2px 6px -4px rgba(0, 0, 0, 0.14);
    div {
      display: flex;
      align-items: flex-end;
      padding: 12px 8px;
      overflow-wrap: break-word;
      font-size: 12px;
      line-height: 16px;
      span {
        width: 160px;
      }
      svg {
        margin-left: auto;
      }
    }
    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      top: -6px;
      right: 16px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent $gray-1 transparent;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .copy-dropdown {
    & .bn-dropdown__overlay {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);

      padding-left: 26px;
      min-width: 100px;
      max-width: calc(100vw - 24px);
      width: max-content;
      &::after {
        content: none;
      }
      div {
        position: relative;
        font-size: 12px;
        line-height: 16px;
        p {
          overflow-wrap: break-word;
          word-break: break-all;
          font-size: 12px;
          line-height: 16px;
        }
        svg {
          left: -16px;
          top: 10px;
          position: absolute;
          margin-left: unset;
        }
      }
    }
  }
}