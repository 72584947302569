.ant-radio-checked .ant-radio-inner {
  border-color: $main;
}

.ant-radio-inner::after{
  background-color: $main;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: $main;
}