@media screen and (max-width: $max-md-media) {
  .transactions-filter {
    width: 720px;
    row-gap: 24px;
    margin: auto;
    .select-assets {
      width: 180px;
    }
    .date-picker {
      margin-right: 16px;
      width: 228px;
    }
    &__search {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .transactions-filter {
    width: 100%;
  }
}

$mobile-filter-position-media-width: 611.98px;
@media screen and (max-width: $mobile-filter-position-media-width) {
  .transactions-filter {
    flex-direction: column;
    &__btn {
      margin-left: 0;
    }
    &--flex {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .select {
        margin-right: 0 !important;
        width: 148px !important;
      }
    }
    .select {
      margin-right: 0;
      width: 100%;
    }
    .date-picker {
      width: 100%;
      margin-right: 0;
    }
    &__search {
      margin-left: 0;
      width: 100%;
    }
  }
}
