.assets-single-skeleton{

  .ant-skeleton-header{
    padding: 1rem;
  }

  .ant-skeleton-content{
    padding: 1rem;
  }
}
