// *** COLORS *** //

$main: #748ADA;
$gray-main: #39393C;

$light-yellow: #FFF8B8;
$yellow: #EEBB05;

$brown-1: #edd4c4;
$brown-2: #e1b79d;
$brown-3: #d59b76;
$brown-4: #c97e4f;
$brown-5: #b06536;
$brown-6: #894e2a;
$brown-7: #62381e;
$brown-8: #3b2212;
$brown-9: #21130a;
$gray-1: #ffffff;
$gray-2: #f3f4f9;
$gray-3: #eeeff3;
$gray-4: #e8e9f0;
$gray-5: #d7d8e0;
$gray-6: #b9bbc2;
$gray-7: #8c8d94;
$gray-8: #626368;
$gray-9: #232325;
$gray-light: #fafafc;

$blue-2: #99ACF2;
$blue-3: #475DAF;

$error-2: #E5424C;

$green-1: #219653;
$green-3: #59bd83;

$red-1: #FFF1F0;
$red-1-hover: #FFE5E3;
$red-2: #FFCCC7;
$red-3: #FFA39E;
$red-4: #ff7875;
$red-5: #ff4d4f;
$red-6: #e6424c;
$red-7: #e92e3b;
$dust-red-7:#AC0913;


$complementary: #FF6D88;

$dark-blue: #00d5d7;
$light-blue: #00e7e6;
$accept-light: #effbf4;

$pink: #D64686;
$pink-light: #FFC5DA;
$lettuce: #CDF964;
// *** Info block colors *** //
$info-block-incognito: #69E299;
$info-block-deadline: #9E83F8;
$info-block-fine: #FF778F;

// *** Media width *** //
$xs-media: 0px;
$more-xs-media: 320px;
$md-xs-media: 360px;
$sub-sm-media: 430px;
$sub-sm-media-50: 450px;
$sub-lm-media: 486px;
$sm-media: 576px;
$more-sm-media: 706px;
$md-media: 768px;
$more-md-media: 868px;
$lg-media: 992px;
$x-media: 1024px;
$xl-media: 1200px;
$xxl-media: 1400px;

// *** Max Media width *** //

$max-xs-media: 575.98px;
$max-sm-media: 767.98px;
$max-md-media: 1023.98px;
$max-lg-media: 1199.98px;
$min-xl-media: 1399.98px;

// *** Custom Media width *** //
$pricing-changes: 1240px;
$min-show-sidebar-media: 1336px;
$update-container-margin: 1376px;



// *** Transactions *** //
$exchange: #e9f0fa;
$deposit: #effbf4;
$withdrawal: #fff1eb;
$transfer: #e9f0fa;

// *** Assets *** //
$btc: #f7931a;

// *** Z-index *** //
$header-index: 4;
