.header-mobile {
  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    background: $gray-1;
    position: sticky;
    top: 0;
    z-index: 3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  }

  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__title {
    display: flex;
    flex-direction: column;
    text-align: center;
    span {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
  &__sub-title {
    font-size: 12px;
    line-height: 16px;
    color: $gray-7;
  }
  &__left {
    z-index: 2;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    display: flex;
    align-items: center;
    & a {
      display: flex;
    }
    &__back {
      z-index: 4;
      cursor: pointer;
      width: 16px;
      height: 16px;
      path {
        fill: $gray-8;
      }
    }
  }
  &__right {
    z-index: 2;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    display: flex;
    align-items: center;
    & .sync {
      svg {
        width: 24px;
        height: 24px;
      }
      svg, path {
        fill: $gray-8;
      }
    }
  }
  &__asset-menu {
    display: flex;
    align-items: center;
    & .ant-dropdown-trigger {
      height: 32px;
    }
    svg, path {
      fill: $gray-8;
    }
  }
  &-center {
    position: fixed;
    left: 68px;
    top: 14px;
    display: flex;
    gap: 11px;
    height: 46px;
    &-title {
      margin-top: 9px;
      text-align: start;
    }
    .meta-mask {
      width: 40px;
      height: 40px;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
}
.single-asset {
  height: 78px !important;
  border-radius: 0 0 16px 16px !important;
}
