
.sign-wrapper {
  width: 100%;
  min-height: 100vh;
}

.sign {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  &-error_check {
    width: 100%;
    color: $red-5;
    font-size: 14px;
    line-height: 22px;
  }
  &-form_extra_wrapper {
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    max-width: 948px;
    background: $gray-2;
    box-shadow: 0 4px 35px 2px rgba(0, 0, 0, 0.25);
  }
  &-form_wrapper {
    margin: auto 0;
    width: 100%;
  }

  &-title {
    text-align: center;
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
  }

  &-icons {
    display: flex;
    justify-content: center;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 40px;
      }
      @include Transition;
      &:hover {
        span {
          color: $main;
        }
      }
      div {
        margin-bottom: 5px;
        width: 60px;
        height: 60px;
      }

      span {
        color: $gray-8;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  &-or {
    display: flex;
    align-items: center;
    margin: 24px auto 24px auto; // 24px - 16px (label in auth-form)
    max-width: 384px;

    &_text {
      margin: 0 16px;
      font-size: 14px;
      line-height: 24px;
    }

    &_line {
      width: 100%;
      height: 1px;
      background: $gray-6;
    }
  }
  &-footer {
    text-align: center;
    margin-top: 16px;
    font-size: 14px;
    line-height: 22px;
    color: $gray-9;
    &_text {
      margin-right: 4px;
    }
    &_link {
      margin-left: 4px;
      font-weight: 500;
      color: $main;
      text-decoration: underline;
      &:hover {
        color: $blue-3;
        text-decoration: underline;
      }
    }
    &__lang {
      margin-top: 16px;
    }
  }
}

._signIn {
  .sign-form_extra_wrapper {
    height: 100%;
  }
}

._signUp {
  .sign-form_extra_wrapper {
    height: 100%;
  }
}

._isPasswordSign {
  .sign-form_extra_wrapper {
    height: 100%;
  }
}

._isLoginSign {
  .sign-forgot_reset {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
    .sign-form_btn {
      margin: 0;
    }
    .sign-form_input {
      margin-top: 8px;
    }
  }
  .sign-form_wrapper {
    max-height: none;
  }
}
