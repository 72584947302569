.sign-validate {
  margin: 8px 0 16px 8px;
  .validate-item {
    display: flex;
    align-items: center;
    &_circle {
      margin-right: 8px;
      width: 8px;
      height: 8px;
      background: $gray-6;
      border-radius: 20px;
    }
    &_text {
      color: $gray-7;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
