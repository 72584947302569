@mixin FlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin Scroll {
  ::-webkit-scrollbar {
    width: 6px;
    opacity: 0.6;
    background-color: $gray-1;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    max-height: 1% !important;
    height: 1% !important;
    background-color: $gray-6;
  }
  ::-webkit-scrollbar-track {
    background-color: $gray-1;
  }
}

@mixin Ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin TransparentBtn {
  border: 1px solid $main !important;
  color: $main !important;
  background: inherit !important;
  svg {
    width: 11px;
    height: 11px;
  }
  svg path {
    fill: $main !important;
  }
  &:disabled {
    border: 1px solid $gray-5 !important;
    color: $gray-5 !important;
    svg path {
      fill: $gray-5 !important;
    }
  }
}

@mixin ScrollWithoutLine {
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}

@mixin Transition {
  transition: all .1s ease-in;
}


@mixin NoSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
