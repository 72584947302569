.radio {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: $main;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: $main;
    border-color: $main;
  }

  .ant-radio-button-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: $gray-light;
    border-color: $gray-5;
  }

  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
    &:hover {
      color: $main;
    }
  }
  .ant-radio-button-wrapper-checked {
    &:focus-within {
      box-shadow: none;
    }
    &:hover {
      color: $main;
      border-color: $main;
    }
  }
}