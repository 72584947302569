.public-header {
  @include Transition;
  z-index: 3;
  height: 72px;

  &.stayedUpstairs {
    position: sticky;
    top: -72px;
  }
  &.goDownOnScrollTop {
    position: sticky;
    top: 0;
  }

  .new-header__right > * {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  &__create-portfolio {
    height: 40px;
    border-radius: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding: 9px 78px;
  }
  &__back {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }
  &__account {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    &__icon {
      position: relative;
      width: 40px;
      height: 40px;

      &__skeleton {
        border-radius: 50%;
        background-color: $gray-3;
        width: 40px;
        height: 40px;
      }
      & > img {
        width: 40px;
        height: 40px;
      }
      & .account-beta-badge {
        top: -4px;
        right: -12px;
      }
    }
    &__data {
      margin-left: 16px;
      &__top {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
      &__const {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $gray-8;
      }
      &__name {
        min-height: 24px;;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.public-header-mobile {
  &__settings {
    width: 24px;
    height: 24px;
    cursor: pointer;
    &, & path {
      fill: $gray-8
    }
  }
  &__login {
    cursor: pointer;
    height: 24px;
    width: 24px;
  }
  &__logo {
    svg {
      width: 63.33px;
      height: 20px;
    }
  }
}