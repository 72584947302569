.dashboard-assets-value {
  width: 100%;
  & .dashboard-card__content {
    padding: 0 30px 24px 24px;
  }
  &__line-chart {
    width: auto;
    height: 300px;
    .highcharts-credits {
      display: none;
    }
  }
  &__headed {
    display: flex;
    flex-direction: column;
  }
  &__filters {
    position: absolute;
    top: 24px;
    left: 265px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    &.en {
      left: 187px;
    }
    &__tabs {
      margin-right: 44px;
      display: flex;
      flex-direction: row;
    }
    &__tab {
      padding: 2px 12px;
      font-size: 12px;
      line-height: 24px;
      background: $gray-1;
      border: 1px solid $gray-4;
      border-radius: 4px;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 16px;
      }
      &.active {
        color: $gray-9;
        background: $gray-4;
      }
    }
  }
  &__scoreboard {
    margin-top: 34px;
    margin-left: 16px;
    margin-bottom: 54px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__item {
      width: 190px;
      position: relative;
      display: flex;
      flex-direction: column;
      &:not(:first-child) {
        padding-left: 24px;
        &:before {
          content: "";
          position: absolute;
          text-align: center;
          top: 11px;
          left: 0;
          width: 1px;
          height: 32px;
          border-right: 1px solid $gray-5;
        }
      }
      &__header {
        display: flex;
        align-items: inherit;
        svg {
          cursor: pointer;
          width: 12px;
          height: 12px;
          path {
            fill: $gray-6;
          }
        }
      }
      &__title {
        margin-right: 4px;
        font-size: 14px;
        line-height: 22px;
        color: $gray-8;
      }
      &__content {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        &.green {
          color: $green-3;
        }
        &.red {
          color: $red-6;
        }
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .dashboard-assets-value {
    &__filters {

    }
  }
}
@media screen and (max-width: $max-sm-media) {
  .dashboard-assets-value {
    width: 100%;
    & .dashboard-card__content {
      display: flex;
      flex-direction: column-reverse;
      padding: 0;
      padding-bottom: 24px;
    }
    &__filters {
      position: static;
      margin: 16px 16px 8px 16px;
      &__tabs {
        margin-right: 0;
      }
      &__tab {
        white-space: nowrap;
        &.active {
          border-color: $gray-main;
          color: $gray-1;
          background: $gray-main;
        }
      }
    }
    &__line-chart {
      padding: 0 8px;
      width: auto;
      height: auto;
      .highcharts-credits {
        display: none;
      }
    }
    &__scoreboard {
      width: auto;
      margin: 0 16px 0 16px;
      flex-direction: column;
      &__item {
        &:not(:first-child) {
          border-top: 1px solid $gray-3;
          padding-left: 0;
          &:before {
            content: none;
          }
        }
        margin-right: 0;
        width: 100%;
        padding: 8px 0 6px 0;
        flex-direction: row;
        justify-content: space-between;
        &__content {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}
@media screen and (max-width: $md-xs-media) {
  .dashboard-assets-value {
    &__filters {
      &__tabs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &__tab {
        &:not(:last-child) {
          margin-right: 0;
        }
      }

    }
  }
}
