.joyride-tooltip {
  background-color: $gray-1;
  border-radius: 8px;
  box-sizing: border-box;
  color: $gray-9;
  font-size: 16px;
  max-width: 100%;
  padding: 36px 24px 24px 32px;
  position: relative;
  width: 384px;
  &__data {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  &__title {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 0;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    &__icon {
      margin-right: 8px;
      width: 32px;
      height: 32px;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
  &__content {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray-8;
  }
  &__footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  &__size {
    margin-left: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $gray-8;
  }
  &__next{
    width: 92px;
    margin-left: 16px;
  }
  &__back {
    padding: 5px 8px;
  }
  &__close {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
    padding: 15px;
    appearance: none;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.react-joyride__overlay {
  height: 100vh !important;
  overflow-y: hidden;
  z-index: 1001;
}

.__floater.__floater__open {
  z-index: 1001 !important;
}

div[class^='react-joyride-step'] {
  z-index: 1001;
}

