.session-item {
  padding-bottom: 0;
  border-bottom: none;
  &_list {
    margin-bottom: 24px;
    margin-left: 24px;
    .list-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    .list-text {
      margin: 8px 0;
      color: $gray-8;
      font-size: 16px;
      line-height: 24px;
    }
    .list-btn,
    .list-active {
      color: $main;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    .list-active {
      color: $green-3;
    }
  }
  &_list:last-child {
    margin-bottom: 0;
  }
}
