.download {
  display: flex;
  align-items: center;
  color: $main;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
  &__icon {
    margin-right: 8px;
  }
}