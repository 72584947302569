
.animated-visibility-enter {
  opacity: 0;
  z-index: 1;
}

.animated-visibility-enter.animated-visibility-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.animated-visibility-exit {
  opacity: 1;
  z-index: 1;
}

.animated-visibility-exit.animated-visibility-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}
