
.auth-carousel__transition-enter {
  opacity: 0;
  z-index: 1;
}

.auth-carousel__transition-enter.auth-carousel__transition-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.auth-carousel__transition-exit {
  opacity: 1;
  z-index: 1;
}

.auth-carousel__transition-exit.auth-carousel__transition-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}


.auth-carousel {
  height: 100vh;
  position: relative;
  overflow: hidden;

  &__wrapper {
    background-color: $gray-1;
    width: 100%;
    height: 100vh;
  }

  &__content {
    z-index: 2;
    top: 140px;
    left: 120px;
    position: absolute;
    &__logo {
      text-align: left;
      margin-bottom: 24px;
      svg {
        height: 40px;
        width: 150px;
      }
      &.bitOk {
        svg {
          width: 126px;
        }
      }
    }
    &__text {
      width: 377px;
      margin-bottom: 45px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: $gray-9;
    }
    &__slider-control {
      &__item {
        cursor: pointer;
        position: relative;
        text-indent: 28px;
        font-weight: 400;
        font-size: 12px;
        line-height: 144%;
        color: $gray-7;
        margin-bottom: 16px;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 3px;
          width: 10px;
          height: 12px;
          background: $gray-7;
          border: 1px solid $gray-7;
          border-radius: 2px;
        }
        &:not(:last-child) {
          &:after {
            content: "";
            position: absolute;
            width: 1px;
            height: 8px;
            left: 4px;
            top: 21px;
            //border: 1px solid #748ADA;
            background: $gray-7;
          }
        }
        &.active {
          font-size: 16px;
          line-height: 144%;
          color: $gray-9;
          &:before {
            top: 6px;
            background: $main;
            border: 1px solid $main;
          }
          &:after {
            top: 25px;
            background: $main;
          }
        }
      }
    }
  }
  &__slider-1 {
    &__dashboard {
      width: 602px;
      position: absolute;
      top: 490px;
      right: -161px;
    }
    &__okman {
      position: absolute;
      top: 637px;
      left: 0;
    }
  }
  &__slider-2 {
    &__document {
      width: 770px;
      position: absolute;
      top: 490px;
      right: -297px;
    }
    &__okman {
      position: absolute;
      top: 668px;
      left: 0;
    }
    &__segment {
      position: absolute;
      top: 861px;
      right: 358px;
    }
  }
  &__slider-3 {
    &__aml {
      width: 770px;
      position: absolute;
      top: 490px;
      right: -297px;
    }
    &__okman-big {
      position: absolute;
      top: 486px;
      left: 13px;
    }
    &__okman-small {
      position: absolute;
      top: 880px;
      right: 451px;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .auth-carousel {
    &__content {
      left: 80px;
    }
  }
}

@media screen and (max-width: $lg-media) {
  .auth-carousel {
    &__content {
      left: 40px;
    }
  }
}
