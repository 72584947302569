.copy-link {
  &.default-btn.transparent {
    color: $main;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    border: none;
    svg, path {
      fill: $main;
    }
  }
}