.aml-public {
  &__download {
    position: absolute;
    top: 40px;
    right: 56px;
    &__icon {
      margin-left: 16px;
      width: 24px;
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
      }
      svg, path {
        fill: $gray-8
      }
    }
  }
}
