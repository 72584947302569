.service-modal {
  overflow: hidden;
  border-radius: 15px;
  &_header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f3f4f9;
    border-radius: 15px 15px 0 0;
    padding: 24px 24px 24px 24px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  }
  &_go-back {
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 12px;
    top: 18px;
    cursor: pointer;
  }
  &_title {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
  }
  &_sub-title {
    position: absolute;
    top: 56px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $gray-7;
  }
  &_content {
    padding: 24px 0 32px 0;
    margin: 0 auto;
    max-width: 460px;
  }
  &_description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  &_btn {
    height: 40px;
    width: 100%;
  }
  &-promo-form {
    margin-top: 24px;
    margin-bottom: 16px;
    &_content {
      position: relative;
    }
    &_input {
      width: 324px;
    }
    &_submit-btm {
      position: absolute;
      top: 26px;
      right: 0;
      height: 40px;
      color: $main;
    }
    &_prices {
      margin-top: 24px;
      &_actual {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $main;
      }
      &_not-relevant {
        margin-right: 6px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-decoration-line: line-through;
        color: $gray-6;
      }
    }
  }
  &-activated {
    &_card {
      margin-bottom: 24px;
    }
  }
}

.service-modal-select {
  margin-top: 24px;
  margin-bottom: 32px;

  &-item {
    padding: 10px 8px 8px 10px;
    background: $gray-light;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    &_header {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    &_content {
      display: flex;
      align-items: end;
      justify-content: space-between;
    }
    &_description {
      max-width: 330px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $gray-8;
    }
    &_price {
      width: 80px;
      padding: 8px;
      background: $gray-2;
      border-radius: 4px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $main;
    }
  }
}


.service-modal-selected {
  padding: 10px 8px 8px 10px;
  background: $gray-light;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  &_header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &_description {
    max-width: 330px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $gray-8;
  }
}
span.ant-radio + * {
  padding-right: 0;
  padding-left: 8px;
}