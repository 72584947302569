.dashboard-risk-score {
  position: relative;
  width: 384px;
  height: 312px;
  padding: 24px;
  background: $gray-1;
  border: 1px solid $gray-4;
  border-radius: 32px 8px 8px 8px;

  & .dashboard-card__title {
    padding: 0;
  }
  & .dashboard-card__content {
    position: relative;
    height: 80%;
  }

  &__wrapper {
    position: relative;
  }
  &__header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      transform: matrix(1, 0, -0.01, 1, 0, 0);
    }
    &__wallet {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
    span {
      width: 16px;
      height: 16px;
    }
    svg, path {
      fill: $gray-7;
    }
    svg {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
  &__progress {
    position: relative;
    display: flex;
    justify-content: center;
    & > svg {
      width: 240px;
      perspective: 70px;
      transform-style: preserve-3d;
    }
    &.showShield {
      text {
        display: none;
      }
    }
    &__shield.add {
      cursor: pointer;
    }
    &__shield {
      position: absolute;
      top: 68px;
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 40px;
      & span > svg {
        width: 37.6px;
        height: 37.6px;
        fill:$gray-8;
      }
      .message {
        max-width: 288px;
        font-size: 14px;
        line-height: 24px;
        color: $gray-8;
        text-transform: capitalize;
      }
      .add {
        max-width: 288px;
        font-size: 14px;
        line-height: 24px;
        color: $gray-8;
        text-transform: capitalize;
        position: absolute;
        top: 42px;
        width: max-content;
      }
    }
    &__shield:hover {
      & span > svg {
        fill:$pink;
      }
      .message {
        color: $pink;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 134px;
    right: 0;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $gray-8;
    transform: matrix(1, 0, -0.01, 1, 0, 0);
    &__link {
      margin-top: 16px;
      &.transparent {
        height: 40px;
        border: 1px solid $gray-5;
        border-radius: 40px;
        padding: 5px 43px;
        span {
          color: $gray-8;
          font-size: 14px;
          line-height: 22px;
        }
        &:hover {
          border: 1px solid $gray-5;
          background: $gray-2;
        }
      }
      &.risk span {
        color: $pink !important;
      }
    }
    &__noLink {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      color: $gray-8;
    }
    .error {
      color: $red-6;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .dashboard-risk-score {
    width: 100%;

    &__wrapper {
      width: 100%;
    }
  }
}
@media screen and (max-width: $max-sm-media) {
  .dashboard-risk-score {
    z-index: 1;
    width: 100%;
    height: 100px;
    padding: 16px 110px 16px 16px;
    border-radius: 4px;
    border: 1px solid $gray-4;
    & .dashboard-card__content {
      position: unset;
      height: auto;
    }
    &__wrapper {
      width: 100%;
      margin-bottom: 0;
      position: relative;
    }
    &__header {
      align-items: start;
      justify-content: left;
      margin-bottom: 0;
      &.center {
        text-align: center;
        height: auto;
      }
      &__title {
        margin-bottom: 2px;
        font-size: 14px;
        line-height: 22px;
        justify-content: right;
      }
      &__wallet {
        line-height: 18px;
        color: $gray-7;
        margin-top: 0;
        margin-bottom: 11px;
      }
    }
    &__progress {
      z-index: 1;
      padding: 8px;
      position: absolute;
      border-radius: 50%;
      top: -17px;
      right: 10px;
      height: 86px;
      width: 86px;
      background-color: $gray-1;
      &__border {
        z-index: 0;
        position: absolute;
        border-radius: 50%;
        top: -17px;
        right: 11px;
        width: 86px;
        height: 86px;
        background-color: $gray-4;
      }
      &__shield {
        height: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        & span > svg {
          width: 24px;
          height: 24px;
          fill:$pink
        }
        .message {
          font-size: 12px;
          line-height: 18px;
          margin-top: 23px;
          color: $gray-7;
          min-width: max-content;
        }
        .no-risks {
          color: $gray-9;
        }
        .add {
          font-size: 14px;
          line-height: 18px;
          margin-top: 13px;
        }
      }
      &__shield:hover {
        .message {
          color: $gray-8;
        }
      }
    }
    &__footer {
      position: relative;
      align-items: baseline;
      top: 0;
      text-align: left;
      font-size: 12px;
      line-height: 16px;
      a {
        font-size: 12px;
        line-height: 18px;
        color: $main;
        text-decoration: underline;
      }
      &__noLink {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        text-align: start;
        color: $gray-7;
      }
      &__transaction {
        color:$pink !important;
      }
    }
  }
}
