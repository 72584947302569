.copy-tooltip {
  &__overlay {
    .ant-tooltip-inner {
      padding: 12px 8px;
      border-radius: 8px;
    }
    svg {
      margin-left: auto;
      float: right;
    }
    &__content {
      position: relative;
      color: $gray-9;
      cursor: pointer;
      padding-right: 16px;
    }
    &__text {
      font-size: 12px;
      line-height: 16px;
    }
    &__icon {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}