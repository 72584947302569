.dashboard-mobile-onboarding {
  z-index: 1001;
  .ant-drawer-wrapper-body {
    background: $gray-4;
  }
  .ant-drawer-body {
    padding: 0;
  }
  &__activate-btn {
    padding: 4px 12px 4px 12px;
    position: absolute;
    cursor: pointer;
    border: none;
    top: 8px;
    left: 0;
    background: $pink;
    z-index: 1;
    border-radius: 0 40px 40px 0;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $gray-1;
    @include Transition;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      width: 16px;
    }
    &.show {
      z-index: 1000;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  }
  &__carousel {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__img {
      width: 300px;
      height: 600px;
      position: relative;
      overflow: hidden;
      img {
        width: 300px;
        height: 600px;
      }
    }
    &__content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 48px 32px 24px 32px;
      background: $gray-1;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      border-radius: 20px 20px 0 0;
    }
    &__steps {
      position: absolute;
      top: 24px;
      right: 32px;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    &__step {
      cursor: pointer;
      position: relative;
      width: 10px;
      height: 8px;
      background: $pink-light;
      border: 1px solid $pink-light;
      border-radius: 2px;
      &:not(:last-child) {
        margin-right: 16px;
        &:after {
          content: "";
          position: absolute;
          width: 8px;
          height: 1px;
          top: 3px;
          left: 13px;
          background: $pink-light;
        }
      }
      &.active {
        background: $pink;
        border: 1px solid $pink;
        &:after {
          background: $pink;
        }
      }
    }
    &__title {
      display: flex;
      align-items: baseline;
      flex-direction: row;
      width: 100%;
      padding: 0;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      font-style: italic;
      &__icon {
        margin-right: 8px;
        width: 32px;
        height: 32px;
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $gray-8;
      min-height: 54px;
    }
    &__footer {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__skip {
      &.default-btn.transparent {
        padding: 0;
        color: $gray-7;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        border: none;
      }
    }
    &__next {
      margin-left: auto;
      &.default-btn.transparent {
        padding: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        border: none;
        color: $pink;
      }
    }
  }
}
