html {
  --antd-wave-shadow-color: $main;
  --scroll-bar: 0;
}

::selection {
  color: $gray-1;
  background: $main;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1002 !important;
}
.ant-modal-wrap {
  z-index: 1002 !important;
}
