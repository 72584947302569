.asset-rename-modal {
  &__wrapper {
    padding: 40px 126px 32px 126px;
  }

  &__save-btn {
    width: 100%;
    height: 40px;
    margin-top: 40px;

    & span {
      font-size: 16px;
      line-height: 24px;
    }
  }
  & .ant-modal-header {
    background: $gray-2;
    padding: 24px 48px;
  }

  & .ant-modal-title {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: $max-sm-media) {
  .asset-rename-modal {
    &__wrapper {
      padding: 24px 16px 24px 16px;
    }

    &__save-btn {
      margin-top: 24px;

      & span {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .ant-input {
      padding: 8px 12px;
    }
  }
}
