.dashboard-p2p-exchange {
  width: 588px;
  & .dashboard-card__title {
    color: $gray-main;
    padding-bottom: 60px;
  }
  & .dashboard-card__content {
    padding: 0 20px 8px 0;
  }
  &__headed {
    display: flex;
    flex-direction: column;
  }
  &__filters {
    position: absolute;
    top: 24px;
    left: 190px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &.en {
      left: 210px;
    }
    &__tabs {
      margin-left: 24px;
      display: flex;
      flex-direction: row;
    }
    &__tab {
      padding: 2px 12px;
      font-size: 12px;
      line-height: 24px;
      background: #F3F4F9;
      border-radius: 4px;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 16px;
      }
      &.active {
        color: $gray-1;
        background: $gray-main;
      }
    }
  }
  &__column {
    padding-left: 24px;
  }
  &__scoreboard {
    padding-left: 40px;
    margin-bottom: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__item {
      width: 138px;
      overflow: hidden;
      position: relative;
      padding-left: 24px;
      display: flex;
      flex-direction: column;
      &:first-child {
        padding-left: 0;
      }
      &__header {
        display: flex;
        align-items: inherit;
        svg {
          cursor: pointer;
          width: 12px;
          height: 12px;
          path {
            fill: $gray-main;
          }
        }
      }
      &__title {
        margin-right: 4px;
        font-size: 14px;
        line-height: 22px;
        color: $gray-8;
      }
      &__content {
        color: $gray-main;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        &.green {
          color: $green-3;
        }
      }
    }
  }
}


@media screen and (max-width: $max-md-media) {
  .dashboard-p2p-exchange {
    width: 100%;
  }
}
@media screen and (max-width: $max-sm-media) {
  .dashboard-p2p-exchange {
    & .dashboard-card__title {
      padding-left: 16px;
      padding-bottom: 30px;
    }
    & .dashboard-card__content {
      display: flex;
      flex-direction: column-reverse;
      padding: 0;
    }
    &__filters {
      position: relative;
      top: auto;
      left: auto;
      margin: 16px 16px 8px 16px;
      &.en {
        left: auto;
      }
      &__tabs {
        margin-left: 0;
      }
    }
    &__column {
      padding: 0 4px;
    }
    &__scoreboard {
      width: auto;
      flex-direction: column;
      margin: 16px;
      margin-top: 0;
      padding-left: 0;
      &__item {
        &:first-child {
          padding-left: 0;
        }
        &:not(:first-child) {
          border-top: 1px solid $gray-3;
          &:before {
            content: none;
          }
        }
        width: 100%;
        padding: 8px 0 6px 0;
        flex-direction: row;
        justify-content: space-between;
        &__content {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}
