.exposure-block {
  position: relative;
  margin-top: 40px;
  padding: 24px 40px 40px 24px;
  min-height: 422px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid $gray-4;
  background: $gray-1;
  &__header {
    position: absolute;
    top: 24px;
    left: 40px;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  &__download-icon {
    display: flex;
    align-items: center;
  }
  &__checked_at {
    position: absolute;
    left: 24px;
    bottom: 24px;
    color: $gray-7;
    font-size: 12px;
    line-height: 16px;
  }
  &__data {
    padding-right: 8px;
    padding-bottom: 40px;
    margin-top: 60px;
    width: 460px;
    height: fit-content;
  }
  &__chart {
    margin-top: 24px;
    height: 325px;
    display: flex;
    align-items: center;
    position: relative;
    &__arrow {
      position: absolute;
      right: calc(100% - 15px);
      bottom: 16px;
      &.revert {
        right: auto;
        left: calc(100% - 15px);
      }
    }
    &__error {
      position: absolute;
      right: calc(100% - 15px);
      bottom: 16px;
      &.revert {
        right: auto;
        left: calc(100% - 15px);
      }
    }
  }
  &__indirect-exposure-img {
    margin-top: 16px;
    margin-left: 16px;
    width: 400px;
    height: 325px;
  }
  &__item {
    height: 32px;
    width: 100%;
    padding: 5px 16px;
    font-size: 12px;
    line-height: 22px;
    color: $gray-8;
    display: flex;
    justify-content: space-between;
    span {
      color: $gray-9;
      font-size: 14px;
      line-height: 22px;
    }
    &:not(:first-child) {
      border-top: 1px solid $gray-3;
    }
    &.indirect-exposure {
      &:not(:first-child) {
        border-top: 1px solid $gray-3;
      }
    }
  }
  &__identified {
    margin-top: 24px;
    padding: 28px 12px 24px 12px;
    border-radius: 8px;
    background: $gray-2;
    &__title {
      margin-left: 16px;
      margin-bottom: 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }
  &__empty {
    height: 264px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.loading, &.error  {
      height: 150px;
    }
  }
  &__laoding {
    color: $gray-8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    width: 162px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &__error {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $gray-8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    &__btn {
      margin-top: 16px;
      width: 282px;
      height: 40px;
      border-radius: 4px;
    }
  }
  &__not-checked, &__not-supported {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__title {
      color: $gray-8;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    &__btn {
      margin-top: 16px;
      width: 282px;
      height: 40px;
      color: $gray-1;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  &__not-supported {
    &__title {
      margin-top: 16px;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .exposure-block {
    margin-top: 24px;
    &__data {
      margin-top: 60px;
      width: 324px;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .exposure-block {
    min-height: 300px;
    padding: 24px 8px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: none;
    background-color: transparent;
    &__header {
      top: 27px;
      left: 16px;
    }
    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    //&__download-icon {
    //  display: none;
    //}
    &__checked_at {
      bottom: unset;
      left: unset;
      right: 24px;
      top: 30px;
    }
    &__data {
      margin-top: 24px;
      width: 100%;
      padding-right: 0;
    }
    &__identified {
      margin-top: 12px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 16px 0 16px 0;
      &__title {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .exposure-block__item:first-child {
        margin-top: 0;
      }
    }

    &__item:first-child {
      margin-top: 26px;
    }
    &__empty {
      &.loading, &.error {
        height: 184px;
      }
    }
    &__not-checked, &__not-supported {
      height: 300px;
    }
    &__chart {
      margin-right: 0;
      height: 336px;
      width: 312px;
    }
  }
}

.counterparty-block {
  position: relative;
  margin-top: 40px;
  padding: 24px 24px 40px 24px;
  min-height: 422px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid $gray-4;
  background: $gray-1;
  &__header {
    position: absolute;
    top: 24px;
    left: 40px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  &__download-icon {
    display: flex;
    align-items: center;
  }
  &__checked_at {
    position: absolute;
    left: 24px;
    bottom: 24px;
    color: $gray-7;
    font-size: 12px;
    line-height: 16px;
  }
  &__not-checked, &__not-supported {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__title {
      color: $gray-8;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    &__btn {
      margin-top: 16px;
      width: 282px;
      height: 40px;
      color: $gray-1;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  &__not-supported {
    &__title {
      margin-top: 16px;
    }
  }
  &__data {
    margin-top: 60px;
    padding-bottom: 40px;
    padding-right: 8px;
    width: 460px;
    height: fit-content;
  }
  &__identified {
    margin-top: 24px;
    padding: 28px 12px 24px 12px;
    border-radius: 8px;
    background: $gray-2;
    .text-wrap {
      overflow: hidden;
      text-align: end;
      span {
        display: flex;
        flex-direction: column;
      }
    }
    &__title {
      margin-left: 16px;
      margin-bottom: 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }
  &__item {
    width: 100%;
    padding: 5px 16px;
    font-size: 12px;
    line-height: 22px;
    color: $gray-8;
    display: flex;
    justify-content: space-between;
    span {
      color: $gray-9;
      font-size: 14px;
      line-height: 22px;
    }
    &:not(:first-child) {
      border-top: 1px solid $gray-3;
    }
  }
  &__empty {
    height: 296px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.error {
      height: 150px;
    }
  }
  &__laoding {
    color: $gray-8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    width: 162px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &__error {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $gray-8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    &__btn {
      margin-top: 16px;
      width: 282px;
      height: 40px;
      border-radius: 4px;
    }
  }
  &__chart {
    position: relative;
    margin-top: 60px;
    margin-right: 88px;
    height: 280px;
    width: 280px;
    display: flex;
    align-items: center;
    &__error-close {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .counterparty-block {
    margin-top: 24px;
    &__data {
      margin-right: 48px;
    }
    &__chart {
      height: 260px;
      width: 260px;
      margin-right: 32px;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .counterparty-block {
    min-height: 300px;
    padding: 24px 8px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: none;
    background-color: transparent;
    &__header {
      top: 27px;
      left: 16px;
    }
    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    //&__download-icon {
    //  display: none;
    //}
    &__data {
      margin-top: 24px;
      margin-right: 0;
      width: 100%;
      padding-right: 0;
    }
    &__checked_at {
      bottom: unset;
      left: unset;
      right: 24px;
      top: 30px;
    }
    &__identified {
      margin-top: 12px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 16px 0 16px 0;
      &__title {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .counterparty-block__item:first-child {
        margin-top: 0;
      }
    }
    &__item:first-child {
      margin-top: 26px;
    }
    &__not-checked, &__not-supported {
      height: 300px;
    }
    &__chart {
      margin-top: 24px;
      margin-right: 0;
    }
  }
}


.alerts-detail {
  width: 100%;
  height: 100%;
  padding: 40px 0 30px 0;
  overflow: scroll;
  a {
    color: $main;
    text-decoration: underline;
  }
  &-header {
    width: 628px;
    padding: 0 0 0 16px;
    display: flex;
    justify-content: space-between;
    &__main {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
    }
    &__title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }
    &__sub-title {
      font-size: 14px;
      line-height: 22px;
      .bn-dropdown__overlay {
        left: 0;
        top: 25px;
      }
      .bn-dropdown__overlay::after {
        right: auto;
        left: 10px;
      }
    }
    &__risk-level {
      width: 84px;
      height: 24px;
      border-radius: 40px;
      text-align: center;
      font-size: 12px;
      line-height: 24px;
      &.low {
        background: $gray-3;
      }
      &.medium {
        background: $red-3;
      }
      &.high {
        color: $gray-1;
        background: $red-6;
      }
      &.severe {
        color: $gray-1;
        background: $red-7;
      }
    }

  }
  &-item {
    width: 302px;
    padding: 0 16px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid $gray-3;
    height: 32px;
    &:nth-child(2n+1) {
      margin-top: 24px;
    }
    &__key {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 22px;
      text-transform: uppercase;
      color: $gray-8;
    }
    &__value {
      @include Ellipsis;
      white-space: nowrap;
      font-size: 14px;
      line-height: 22px;
      b {
        font-weight: 500;
      }
      a {
        color: $main;
        text-decoration: underline;
      }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      padding: 0;
    }
    .ant-select-arrow {
      right: 0;
    }
  }
  &-group {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    &__left, &_right {
      width: 50%;
    }
    &__left {
      padding-left: 16px;
      padding-right: 24px;
    }
    &__bottom {
      padding: 0 40px 0 16px;
      margin-top: 24px;
      width: 100%;
      .alerts-detail-item {
        width: 100%;
        height: 40px;
        &:nth-child(2n+1) {
          margin-top: 0;
        }
      }
    }
    &__assignment {
      color: $main;
    }
    &__status {
      color: $main;
      &__popup {
        .ant-select-item-option-selected {
          display: none;
        }
      }
      &__icon {
        transform: rotate(90deg);
        width: 16px;
        height: 16px;
        path {
          fill: $main;
        }
      }
      &__loading {
        width: 8px;
        height: 8px;
        top: 50%;
        left: -9px;
      }
    }
    &__categories {
      &.active {
        border-bottom: 2px solid $main;
      }
      .alerts-detail-item__value {
        cursor: pointer;
        color: $main;
        font-size: 14px;
        line-height: 22px;
        text-decoration-line: underline;
      }
    }
    &__ellipse {
      flex-wrap: nowrap;
      @include Ellipsis;
      .alerts-detail-item__value {
        margin-top: 4px;
        margin-left: 8px;
      }
      .hint-content {
        @include Ellipsis;
      }

    }
  }
  &-categories {
    padding-left: 16px;
    background-color: $gray-light;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__top {
      width: 100%;
      min-height: 34px;
    }
    &__content {
      padding-bottom: 24px;
      width: 100%;
      display: flex;
      flex-direction: row;
    }
    &__chart {
      margin-left: 16px;
    }
    &__list {
      margin-left: 40px;
      width: 302px;
      height: fit-content;
      &__title {
        margin-left: 16px;
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }
    &__item {
      background-color: $gray-light;
      height: 32px;
      width: 100%;
      padding: 5px 16px;
      font-size: 12px;
      line-height: 22px;
      color: $gray-8;
      display: flex;
      justify-content: space-between;
      //border-bottom: 1px solid $gray-1;
      span {
        color: $gray-9;
        font-size: 14px;
        line-height: 22px;
      }
      &:not(:first-child) {
        border-top: 1px solid $gray-3;
      }
    }
  }
  &-add-comment {
    margin-top: 16px;
    display: flex;
    align-items: flex-end;
    &__textarea {
      padding: 8px 16px;
      width: 355px;
      height: 44px;
      border-color: $gray-3;
    }
    &__submit.default-btn.transparent {
      margin-left: 24px;
      color: $main;
      border-color: $gray-3;
      border-radius: 2px;
      &:disabled {
        background: #f3f4f9;
        border: 1px solid #d7d8e0;
        color: $gray-6;
        &:hover {
          color: $gray-6;
        }
      }
      &:hover {
        color: $blue-2;
      }
      &:focus {
        color: $blue-3;
        border-color: $blue-3;
      }
    }
    .ck-editor__editable {
      min-width: 389px;
      min-height: 60px;
    }
  }
  &-activity-history-section {
    padding: 0 40px 0 16px;
    margin-top: 24px;
    &__title {
      margin-left: 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  &-activity-history-list {
    margin-left: 16px;
    margin-top: 24px;
  }
  &-activity-history {
    position: relative;
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 24px;
      &:before {
        content: "";
        width: 1px;
        height: 26px;
        position: absolute;
        top: -25px;
        left: 8px;
        background-color: $gray-4;
      }
    }
    &__icon {
      width: 16px;
      height: 16px;
    }
    &__content {
      margin-left: 16px;
    }
    &__datetime {
      font-size: 12px;
      line-height: 16px;
    }
    &__title {
      font-size: 12px;
      line-height: 16px;
      span {
        color: $pink;
      }
    }
    &__comment {
      margin-top: 6px;
      color: $gray-8;
      font-size: 12px;
      font-style: italic;
      line-height: 16px;
      width: 604px;
      word-wrap: break-word;
    }
    &.skeleton {
      .ant-skeleton-element .ant-skeleton-avatar {
        width: 16px;
        height: 16px;
      }
      .alerts-detail-activity-history__content {
        display: flex;
        flex-direction: column;
      }
      .alerts-detail-activity-history__content__datetime {
        width: 100px;
        height: 16px;
      }
      .alerts-detail-activity-history__content__title {
        width: 250px;
        margin-top: 4px;
        height: 16px;
      }
    }
  }
  &-activity-history-show-all {
    margin-top: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    &:before {
      content: "";
      width: 1px;
      height: 12px;
      position: absolute;
      top: -17px;
      left: 8px;
      background-color: $gray-4;
    }
    &__icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
      background-color: $gray-3;
      border-radius: 100%;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        width: 16px;
        height: 16px;
        path {
          fill: $gray-7;
        }
      }
    }
    &__text {
      cursor: pointer;
      margin-left: 16px;
      font-size: 12px;
      line-height: 16px;
      text-decoration-line: underline;
      color: $gray-7;
    }
  }
  .loading-page {
    width: 100%;
    height: 100%;
  }
}

.alerts-detail-mobile {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  .alerts-detail-item {
    width: 100%;
    margin-top: 0;
  }
}

.details-empty {
  width: 100%;

  h5 {
    color: $gray-9;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 0 0 16px 6px;
  }

  &__content {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;

    height: 122px;
    background: $gray-1;
    border-top: 1px solid $gray-4;
    border-bottom: 1px solid $gray-4;

    svg {
      height: 24px;
      width: 24px;

      path {
        fill: $gray-5;
      }
    }
  }

  &__title {
    text-transform: lowercase;
    color: $gray-9;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.details-empty__title::first-letter {
  text-transform: uppercase;
}


@media screen and (max-width: $max-sm-media) {
  .alerts-detail {
    &-group {
      &__categories {
        //border-bottom: 2px solid transparent;
        &.active {
          border-color: transparent;
          position: relative;
          .alerts-detail-item__key {
            font-weight: 500;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 16px);
            height: 2px;
            background: $main;
            border-radius: 1px 1px 0 0;

          }
        }
      }
    }
    &-categories {
      position: relative;
      padding-left: 0;
      background-color: $gray-1;
      &__top {
        height: 24px;
      }
      &__content {
        flex-direction: column-reverse;
      }
      &__list {
        width: 100%;
        margin-left: 0;
      }
      &__chart {
        margin-top: 24px;
        &.sankey-chart, &.pie-chart {
          margin-left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &__minimize {
        position: absolute;
        bottom: 8px;
        right: 8px;
        transform: rotate(-90deg);
        path {
          fill: $main
        }
      }
    }
    &-activity-history {
      &-list {
        margin-left: 0;
        margin-top: 40px;
      }
      &-section {
        padding: 0 16px;
        margin-top: 40px;
        &__title {
          margin-left: 0;
        }
      }
      &__comment {
        max-width: 100%;
        width: 100%;
      }
    }
    &-add-comment {
      flex-direction: column;
      align-items: baseline;
      &__textarea {
        width: 100%;
        &__wrapper {
          width: 100%;
        }
      }
      &__submit {
        margin-top: 16px;
        &.default-btn.transparent {
          margin-left: 0;
        }
      }
    }
  }
}

.transfers-details {
  margin-bottom: 40px;

  &-main {
    padding: 24px 24px 40px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid $gray-4;
    background: $gray-1;
    &__header {
      margin-bottom: 38px;
      width: 460px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__main {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        a {
          color: $main;
          text-decoration: underline;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          align-items: center;
          svg {
            margin-left: 5px;
          }
        }
      }
      &__sub-title {
        font-size: 14px;
        line-height: 22px;
      }
      &__additional {
        flex-direction: row;
        display: flex;
        align-items: center;
      }
      &__notes {
        margin-left: 8px;
        circle {
          fill: $gray-4;
        }
        path {
          fill: $gray-8;
        }
      }
      &__menu {
        margin-left: auto;
        transform: rotate(90deg);
        &__trigger {
          display: inline-block;
          width: 100%;
          svg, path {
            fill: $pink;
          }
        }
      }
    }
    &__left, &__right {
      width: 460px;
      .alerts-detail-item:first-child {
        margin-top: 0;
      }
    }
    &__right > .alerts-detail-item:last-child,
    &__left > .alerts-detail-item:last-child {
      border-bottom: none;
    }
    .alerts-detail-item {
      width: 100%;
    }
  }
  &-alerts {
    position: relative;
    min-height: 240px;
    margin-top: 48px;
    padding-top: 24px;
    padding-bottom: 32px;
    border-radius: 8px;
    border: 1px solid $gray-4;
    background: $gray-1;
    &.empty {
      position: relative;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      padding-left: 40px;
    }
    &__title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }
    &__risk-title {
      color: $gray-7;
      margin-right: 24px;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;

      &--empty {
        position: absolute;
        top: 26px;
        right: 0;
      }
    }
    .alerts-table {
      border: none;
      border-radius: 0;
    }
    .alerts-show-more {
      margin-top: 0;
    }
    .alerts-table__item:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}


@media screen and (max-width: $max-md-media) {
  .transfers-details {
    &-main {
      &__header, &__left, &__right {
        width: 100%;
        .alerts-detail-item:first-child {
          margin-top: 0;
        }
      }
      &__left {
        .alerts-detail-item:last-child {
          border-bottom: 1px solid $gray-3;
        }
      }
      &__right {
        margin-top: 24px;
      }
    }
    .alerts-table__wrapper {
      width: auto;
      overflow: scroll;
    }
    .alerts-table {
      margin-top: 0;
      width: 996px;
    }
    &-alerts {
      padding-top: 0;
      border-radius: 0;
      border: none;
      background: transparent;
      &.empty {
        max-width: 720px;
        width: 100%;
        margin-left: 80px;
      }
      &__header {
        max-width: 720px;
        width: 100%;
        margin-left: 80px;
      }
      .alerts-table {
        border: 1px solid $gray-4;;
        border-radius: 8px;
      }
      &__risk-title {
        &--empty {
          top: 0;
        }
      }
      .ps__rail-x {
        border-radius: 4px;
        background: $gray-2;
        height: 10px;
        margin-left: 80px;
        margin-right: calc(100vw - 720px - 80px);
      }
    }
  }
}

@media screen and (max-width: $more-md-media) {
  .transfers-details {
    &-alerts {
      &__header {
        margin-left: auto;
        margin-right: auto;
      }
      &.empty {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .ps__rail-x {
      margin-left: calc((100vw - 720px) / 2);
      margin-right: calc((100vw - 720px) / 2);
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .transfers-details {
    margin-top: 8px;
    &-main-mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      .alerts-detail-item {
        width: 100%;
        &:nth-child(2n+1) {
          margin-top: 0;
        }
      }
    }
    &-alerts {
      margin-top: 40px;
      min-height: unset;
      &__header {
        margin-bottom: 16px;
        padding-left: 24px;
      }
      &__risk-title {
        background: $gray-2;
        padding: 8px 16px;
        margin-right: 8px;
        border-radius: 2px;
        line-height: 16px;
      }
      &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
}
