.unified-tex-report {
  &__step-two {
    padding: 24px 0;
    &__row {
      width: 100%;
      padding-bottom: 32px;
      &__title {
        padding-bottom: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
