.other-navigation {
  height: 100%;

  .dropdown-item {
    padding: 9px 18px 9px 24px;
    min-height: 42px;
    @include Transition;
    &:hover {
      background: $gray-4;
    }
    &.exit {
      color: $red-6;
    }
    &.safety {
      margin-top: 18px;
    }
    &.active {
      color: $main;
    }
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &__text {
    max-width: 240px;
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &_icon {
    margin-left: 8px;
    svg {
      display: block !important;
    }
    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
