.ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after  {
  display: none;
}
.ant-menu-light .ant-menu-item:hover {
  color: $gray-9;
}
.ant-menu-item-selected {
  color: $gray-9;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $gray-2;
}
.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: $gray-9;
}
.ant-menu-item a:hover {
  color: $gray-9;
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
  color: $main
}