.default-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid $main;
  border-top: 1px solid $gray-1;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin .7s linear infinite;
}

@keyframes spin {
  0% { transform:translate(-50%, -50%)  rotate(0deg) }
  100% { transform:translate(-50%, -50%)  rotate(360deg)}
}
