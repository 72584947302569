.transaction-edit {
  &__file-validate {
    width: 100%;
    color: $red-5;
    font-size: 14px;
    line-height: 22px;
  }
  &__confirm-modal {
    max-width: 480px !important;
    width: 100% !important;
  }
  &__form {
    .field-wrapper {
      margin-top: 0;
      height: 40px;
      input {
        margin-top: 0;
      }
    }
    &-fio-wrapper, &-sum-wrapper, &-drop-wrapper, &-type-wrapper {
      margin-bottom: 24px;
    }
    &-country-source {
      margin-bottom: 16px;
      &__selectors {
        .field-wrapper {
          width: 100% !important;
          margin-top: 16px !important;
          height: auto !important;
        }
        .ant-select-selection-search-input {
          padding-top: 8px !important;
        }
        .ant-select-selector {
          height: 40px !important;
        }
        .ant-select-selection-item {
          padding: 4px 0 !important;
        }
        .ant-select-selection-placeholder {
          padding: 4px 0 !important;
        }
        .ant-select {
          margin-top: 8px !important;
        }
      }
    }
    &-sum-wrapper {
      display: flex;
      flex-direction: column;
      .ant-select-selection-item {
        height: 85px;
      }
    }
    &-btn {
      width: 100%;
      height: 40px;
    }
    &-sum {
      width: 172px;
      //border-radius: 2px;
      border: 1px solid $gray-5;
      &-input {
        display: flex;
        align-items: center;
      }
      &-previous {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $gray-7;

        &-amount {
          cursor: pointer;
          color: $main;
          text-decoration: underline;
        }
      }
    }
    &-text-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      svg {
        margin-left: 4px;
        width: 14px;
        height: 14px;
        path {
          fill: $main;
        }
      }
    }
    &-text {
      color: $gray-9;
      font-size: 14px;
      line-height: 18px;
    }
    &-type {
      height: auto !important;
      margin-bottom: 24px;
      & .ant-select-selector {
        height: 40px !important;
      }
      & .ant-select-selection-item {
        padding-top: 4px !important;
      }
    }
    &-drop-text {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      color: $gray-9;
    }

    &-sold {
      &-wrapper {
        margin-bottom: 16px;
      }
      &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $gray-7;
      }
      &__value {
        font-weight: 500;
        color: $gray-9;
      }
    }
  }
}
