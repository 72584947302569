.assets-single {
  &.bn-dropdown__overlay {
    left: 0;
  }

  &-account-info {
    border-radius: 4px;
    border: 1px solid $gray-4;
    background: $gray-light;
    padding: 24px 16px 19px 16px;
    height: fit-content;
    .block:first-child {
      p:first-child {
        span:last-child {
          text-transform: none;
        }
      }
    }
    h4 {
      color: $gray-9;
      padding-left: 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      position: relative;
      .default-spinner {
        width: 12px;
        height: 12px;
        left: 107%;
      }
    }
    p {
      width: 384px;
      height: 32px;
      flex-shrink: 0;
      border-bottom: 1px solid $gray-3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      &:last-child {
        border-bottom: none;
      }
      &:nth-child(4) {
        margin: 4px 0;
      }
      &:nth-child(3) {
        span:last-child {
          text-transform: uppercase;
        }
      }
      .alerts-detail-item {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 0;
        width: 100%;
        border-bottom: none;
        padding: 0;
        height: 100%;
      }
      span {
        display: block;
        color: $gray-8;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-transform: uppercase;

        &:last-child {
          color: $gray-9;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          text-transform: lowercase;
          &::first-letter {
            text-transform: capitalize;
          }
        }
      }
      span.risk {
        cursor: pointer;
        color: $red-7;
        text-decoration-line: underline;
        &.severe {
          color: $dust-red-7;
        }
        &.high {
          color: $error-2;
        }
        &.medium {
          color: $red-3;
        }
        &.low {
          color: $gray-7;
        }
        &.no_risk, &.none {
          color: $gray-9;
        }
        &.null {
          cursor: default;
          color: $gray-9;
          text-decoration-line:none;
        }
      }
      span.transaction {
        color: $main;
        text-align: right;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
    .block {
      h4 {
        margin-bottom: 16px;
        width: fit-content;
      }
      &:nth-child(1) {
        margin-bottom: 24px;
        p:nth-child(2) span:nth-child(2) {
          text-transform: capitalize !important;
        }
      }
      &:nth-child(2) {
        p:nth-child(3) span:nth-child(2) {
          text-transform: lowercase !important;
        }
        h4 .default-spinner {
          left: 110%;
        }
      }
    }
  }

  &-wrapper {
    background-color: $gray-1;
    min-height: 330px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    border: 1px solid $gray-4;
  }
  &__header {
    position: relative;
    &_wrapper {
      background: $gray-1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 40px 40px 16px 40px;
      border-top-right-radius: 7px;
      border-top-left-radius: 7px;
    }
    &_data {
      margin-left: 16px;
      &_wrapper {
        display: flex;
        justify-items: center;
        width: 75%;
      }
      &_top {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }
    &_right {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 24px;
    }
    &_vector {
      svg, path {
        fill: $pink;
      }
    }
    &__risk {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: auto;
      border: 1px solid $green-3;
      border-radius: 30px;
      padding: 6px 16px;
      margin-right: 16px;
      @include Transition;
      &:hover {
        background-color: $gray-2;
      }
      &.warning {
        border-color: $red-3;
      }
      &.error {
        border-color: $red-6;
      }
      &__icon {
        margin-right: 8px;
        width: 24px;
        height: 24px;
      }
      &__content {
        display: flex;
        flex-direction: column;
        &__score {
          font-weight: 500;
          font-size: 8px;
          line-height: 9px;
          color: $gray-6;
        }
        &__result {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: $gray-8;
        }
      }
    }
    &_icon {
      position: relative;
      width: 40px;
      height: 40px;

      &__skeleton {
        border-radius: 50%;
        background-color: $gray-3;
        width: 40px;
        height: 40px;
      }
      & > img {
        width: 40px;
        height: 40px;
      }
      & .account-beta-badge {
        top: -4px;
        right: -12px;
      }
    }
    &_name {
      margin-right: 8px;
      max-width: 243px;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }


    &_total-cost {
      color: $gray-8;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      &.error {
        color: $red-6;
      }
    }
    &_menu {
      & .bn-dropdown__overlay {
        border-top: 1px solid $gray-4;
        background-color: $gray-1;
        left: 0;
        top: 30px;
        border-radius: 0 0 10px 10px;
      }
      & .dropdown-item:hover {
        background: $gray-2;
        color: $main;
      }
      & .dropdown-item:not(:first-child) {
        margin-top: 5px;
      }
      &_delete {
        & span {
          color: $red-6;
        }
      }
      &_copy-address {
        display: flex;
        justify-content: space-between;
        svg, path {
          fill: $main;
        }
      }
    }
  }
  &__sub_header {
    &_wrapper {
      display: flex;
      justify-content: space-between;
      padding: 8px 32px 8px 36px;

      &.success {
        background: #effbf4;
      }

      &.error {
        background: #fff1f0;
      }

      &.info {
        background: #f6ffed;
      }
    }
  }
  &__content {
    &_wrapper {
      background: $gray-1;
      display: flex;
      justify-content: space-between;
      padding: 11px 23px 23px 24px;
      &.noShowMoreBtn {
        padding-bottom: 22px;
      }
    }
  }
  &__total-balance {
    &_currency {
      margin-bottom: 24px;
    }
    &_wrapper {
      margin-top: 40px;
    }

    &_rub {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & span:first-child {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: $gray-8;
        margin-right: 12px;
      }
      & span:last-child {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: right;
        color: $gray-9;
      }
    }
    &_usd {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: $gray-7;
      margin-bottom: 24px;
    }
    &_transactions {
      text-align: right;

      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $main;
        text-decoration: none;

        &.underline {
          text-decoration-line: underline;
        }

        &:hover {
          color: $blue-2;
          text-decoration-line: none;
        }
      }
    }
  }
  &__sync-account {
    position: absolute;
    bottom: -11px;
    right: -5px;

    button {
      background: $gray-4;
      padding: 0;
      width: 20px;
      cursor: pointer;
      height: 20px;
      border-radius: 50%;

      &:hover, &:active, &:focus  {
        background: $gray-4;
      }

      @include Transition;
      svg {
        margin-right: 8px;
        width: 16px;
        height: 16px;
        path {
          fill: $gray-8;
        }
      }
      svg, path {
        @include Transition;
      }
      &:focus {
        color: $gray-7;
      }
      .sync-loader {
        position: absolute;
        left: 10px;
        top: 10px;
      }
      &.error {
        color: $red-6;
        svg path {
          fill: $red-6;
        }
        &:hover {
          color: $gray-1;
          background-color: $red-5;
          svg path {
            fill: $gray-1;
          }
        }
      }
      &.synchronizing {
        pointer-events: none;
        color: $gray-7;
        border: none !important;

        svg path {
          fill: $gray-7;
        }
        &:hover {
          svg path {
            fill: rgba(0, 0, 0, 0.25);
          }
        }
        &:focus {
          color: $gray-7;
          svg path {
            fill: $gray-7;
          }
        }
      }
      &.noAccounts {
        color: $gray-1;
        background-color: $pink;
        &:hover {
          opacity: .8;
        }
      }
    }
    .default-btn.transparent:hover {
      color: $gray-8;
      background-color: $gray-4;
      svg path {
        fill: $gray-8;
      }
    }
  }
  &-transactions {
    margin-top: 40px;
    &__title {
      margin-bottom: 24px;
      padding-left: 24px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    &__go-to {
      padding: 11px;
      width: 100%;

      background: $gray-1;
      color: $gray-8;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      cursor: pointer;
      border-radius: 0 0 50px 50px;
      border: 1px solid $gray-3;

      &:hover {
        background: $gray-light;
        text-decoration: underline;
      }
    }
    &__loader {
      position: relative;
      padding: 82px;
      justify-content: center;
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
      & span {
        margin-top: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
      &__spinner {
        .default-spinner {
          width: 40px;
          height: 40px;
        }
        width: 40px;
        height: 40px;
      }
    }
  }
  &-sync-note {
    position: relative;
    padding: 12px;
    padding-left: 32px;
    width: 256px;
    font-size: 12px;
    line-height: 16px;
    color: $gray-8;
    border: 1px solid $gray-3;
    border-radius: 4px;
    &:not(:first-child) {
      margin-top: 16px;
    }
    svg, path {
      fill: $gray-7;
    }
    &.error {
      color: $red-6;
      svg, path {
        fill: $red-6;
      }
    }
    &__icon {
      position: absolute;
      top: 12px;
      left: 8px;
    }
  }
  &-public-wallet {
    min-height: 32px;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    margin-bottom: 16px;
    .share-link {
      padding: 0;
      margin-left: 34px;
      margin-right: 24px;
      &.disabled {
        background-color: transparent;
        svg, path {
          fill: $gray-5;
        }
      }
    }
    .bn-switch .ant-switch.ant-switch-checked {
      background-color: $pink;
    }
  }
}
@media screen and (max-width: $x-media) {
  .assets-single__content_wrapper.noShowMoreBtn {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
  }
  .assets-single-account-info {
    border: none;
    padding: 0;
    background: none;
    h4 {
      font-size: 15px;
      margin-bottom: 10px !important;
    }
    p {
      width:auto !important;
      margin: 6.5px 0;
      &:nth-child(4) {
        margin: 1.5px 0;
      }
    }
    .block:nth-child(1) {
      margin-bottom: 29px;
    }
  }
  .assets-single-wrapper {
    margin-top: 0;
  }
}
.public-wallet {
  cursor: pointer;
  display: flex;
  align-items: center;
  .default-spinner {
    width: 12px;
    height: 12px;
  }

  &-icon {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: 4px;
    &.public {
      background: $info-block-incognito;
    }
    &.private {
      background:$gray-8;
    }
  }
  span:nth-child(2) {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $gray-8;
  }
}


