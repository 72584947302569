.hint {
  &-overlay {
    padding-bottom: 0;
    border-radius: 4px;
    .ant-popover-inner {
      background: $gray-3;
      border-radius: 4px;
      box-shadow:
        0 2px 4px -4px rgba(0, 0, 0, 0.1),
        0px 2px 4px rgba(0, 0, 0, 0.04),
    }
    .ant-popover-inner-content {
      max-width: 192px;
      max-height: 40px;
      overflow: hidden;
      padding: 4px 8px;
      font-size: 11px;
      line-height: 16px;
      overflow-wrap: break-word;
    }
    .ant-popover-arrow {
      display: none;
    }
  }
  &-content {
    align-self: flex-start;
  }
}
