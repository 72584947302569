@media screen and (max-width: $max-md-media) {
  .report-formed {
    &__header {
      flex-direction: row;
      align-items: baseline;
    }
    &__date {
      margin-left: 16px;
    }
    &__content {
      flex-direction: column-reverse;
    }
    .report-download {
      width: 100%;
    }
    .report-calculations {
      margin-left: 0;
      margin-top: 16px;
      &__remaining-day {
        margin-top: 30px;
      }
      &__item-wrapper {
        display: flex;
        flex-wrap: nowrap;
      }
      &__item {
        &:not(:first-child) {
          margin-left: 40px;
        }
        width: auto;
        &--tax_base, &--tax_amount {
          margin-top: 0;
        }
      }
    }
  }
}
@media screen and (max-width: $max-sm-media) {
  .report-formed {
    &__header {
      flex-direction: column;
      align-items: flex-start;
    }
    &__actions {
      margin-top: 0;
    }
    &__title {
      font-size: 16px;
      line-height: 24px;
    }
    &__date {
      margin-left: 0;
      margin-bottom: 0;
      font-size: 12px;
      line-height: 16px;
    }
    &__btn {
      margin: 24px 0 14px 0;
      width: 100%;
    }
    &__delete-btn-wrapper {
      float: right;
      &.default-btn.transparent {
        color: $red-6;
        padding: 5px 0;
        svg, path {
          fill: $red-6;
        }
      }
    }
    .report-calculations {
      margin-top: 0;
      margin-bottom: 24px;
      &__operations {
        margin-bottom: 0;
        order: 2
      }
      &__link {
        font-size: 16px;
        line-height: 22px;
      }
      &__remaining-day {
        margin-top: 20px;
        margin-bottom: 18px;
        &__date {
          font-size: 16px;
          line-height: 22px;
        }
      }
      &__text {
        order: 1;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 18px;
        color: $gray-9;
        &--second {
          display: none;
        }
      }
      &__title {
        margin: 28px 0 18px 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
      &__item-wrapper {
        flex-wrap: wrap;
      }
      &__item {
        width: 50%;
        &:not(:first-child) {
          margin-left: 0;
        }
        &--tax_base, &--tax_amount {
          margin-top: 16px;
        }
        &-header {
          &-title {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
    .report-download {
      margin-bottom: 16px;
      padding: 16px;
      background: $gray-2;
      &__title {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 18px;
      }
      &__item {
        margin-bottom: 8px;
        &-name {
          margin: 0 4px 0 14px;
          font-size: 14px;
          line-height: 20px;
        }
        &-download {
          margin-left: auto;
        }
      }
    }
  }
}

