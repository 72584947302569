.documents-page {
  &.main {
    padding-top: 0;
  }

  &__header {
    p {
      margin-top: 16px;
      margin-bottom: 24px;
      max-width: 588px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $gray-8;
    }
  }
  &__content {
    margin-top: 48px;
    &__title {
      margin-bottom: 34px;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.report-right-side {
  &__remaining {
    display: flex;
    margin: 32px 0 32px 24px;
    height: 42px;
    &-text {
      margin-right: 8px;
      width: 126px;
      color: $gray-9;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.report-left-side {
  &__head {
    margin: 24px 0 40px 0;
    display: flex;
    flex-direction: row;
    align-content: center;
  }
  &__payment {
    margin-left: 24px;
    display: flex;
    align-items: center;
    &-text {
      margin-right: 8px;
      width: 126px;
      color: $gray-9;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
    &-sum {
      color: $main;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }
  }
  &__title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  &__icon-wrapper {
    @include FlexCenter;
    margin-left: 8px;
    margin-top: 2px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
    path {
      fill: $main;
    }
  }
}

.reportLeftSideIconWrapperActive {
  path {
    fill: $gray-5;
  }
}
