span.fast-filter-icon-block {
  position: absolute;
  background: $gray-light;
  cursor: pointer;
  right: 0;
  display: none;
  height: 34px;
  bottom: 0;
  padding: 7px 16px 0 5px;
  svg path {
    fill: $gray-8;
  }
}

span.fast-filter-icon-block.active {
  display: block;
}

.filter-bar {
  overflow: scroll;
  white-space: nowrap;
  display: none;
  align-items: center;
  padding-left: 15px;
  border-radius: 0 0 4px 4px;
  position: relative;
  scrollbar-width: none;
  padding-top: 24px;

  &::-webkit-scrollbar {
    display: none;
  }
  &.active {
    display: flex;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-right: 9px;
    padding: 2px 4px 2px 8px;
    border: 1px solid $gray-4;
    border-radius: 2px;
    background: $gray-2;
    cursor: pointer;
    span {
      margin-right: 5px;
      color: $gray-8;
      font-size: 12px;
      line-height: 24px;
    }
    div {
      @include FlexCenter;
      svg {
        width: 10px;
        height: 10px;
        path {
          color: $gray-8;
        }
      }
    }
  }
}

.mobile-filter {
  z-index: 4;
  .search-transparent__wrapper {
    height: 40px;
    margin: 0;
    margin-bottom: 24px;
    width: 100%;
    background-color: $gray-1;
    border-radius: 2px;
    border: 1px solid $gray-4;
    padding: 3px 12px;
    .search-transparent {
      border-bottom: none;
    }
    &::placeholder {
      color: $gray-6
    }
  }
  .date-picker {
    height: 40px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 8px 12px;
    min-height: 40px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-selection-search,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 4px;
  }
}

.clients {
  &-table {
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-radius: 8px;
    width: 100%;
    margin-top: 24px;
    tr:not(.first-child) {
      cursor: pointer;
      @include Transition;
      &:hover {
        background-color: $gray-light
      }
    }
    th {
      padding: 9px 16px 9px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      background: $gray-2;
      &:first-child {
        padding-left: 8px;
        padding-right: 8px;
        text-align: center;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 22px;
          background-color: $gray-4;
        }
      }
    }
    td {
      height: 56px;
      max-height: 56px;
      padding-left: 8px;
      border-bottom: 1px solid $gray-4;
      &:first-child {
        padding-left: 0;
        position: relative;
      }
    }
    tr:not(:first-child) {
      border-bottom: 1px solid $gray-4;
    }
    &__main {
      font-size: 14px;
      line-height: 22px;
    }
    &__sub {
      display: flex;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      color: $gray-8;
    }
    &__risk-level {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      .clients-table__main {
        @include Ellipsis;
        max-width: 120px;
      }
      &__cel {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 84px;
        height: 24px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 16px;
        &.no_risk, &.none {
          border-radius: 2px;
          border: 1px solid $gray-4;
          background: $accept-light;
        }
        &.low {
          background-color: $gray-1;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23FFA39E' stroke-width='4' stroke-dasharray='10%2c 11' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
        }
        &.medium {
          background: $red-3;
        }
        &.high {
          color: $gray-1;
          background: $red-6;
        }
        &.severe {
          color: $gray-1;
          background: $red-7;
        }
      }
    }
    &__client-id {
      width: 180px;
      min-width: 180px;
      max-width: 180px;
      .clients-table__main {
        @include Ellipsis;
        max-width: 136px;
      }
    }
    &__active-alerts {
      width: 172px;
      min-width: 172px;
      max-width: 172px;
    }
    &__last-alert {
      width: 172px;
      min-width: 172px;
      max-width: 172px;
    }
    &__transfer-valume {
      width: 180px;
      min-width: 180px;
      max-width: 180px;
    }
    &__last-transfer {
      width: 172px;
      min-width: 172px;
      max-width: 172px;
    }
    &.noBorderBottom {
      border-bottom: none;
      td {
        border-bottom: none;
      }
    }
    &__empty {
      margin-top: 200px;
    }
  }
  &-skeleton {
    width: 100%;
    &__risk-level {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-skeleton-button {
        width: 84px;
        height: 24px;
      }
    }
    .clients-table__main, .clients-table__sub {
      .ant-skeleton-button {
        height: 16px;
        width: 100%;
      }
    }
  }
}

.alerts {
  &-table {
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-radius: 8px;
    width: 100%;
    tr:not(.first-child) {
      @include Transition;
      &:hover {
        background-color: $gray-light
      }
    }
    th {
      padding: 9px 16px 9px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      background: $gray-2;
      &:first-child {
        padding-left: 8px;
        padding-right: 8px;
        text-align: center;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 22px;
          background-color: $gray-4;
        }
      }
    }
    td {
      height: 56px;
      max-height: 56px;
      padding-left: 8px;
      border-bottom: 1px solid $gray-4;
      &:first-child {
        padding-left: 0;
        position: relative;
      }
    }
    tr:not(:first-child) {
      border-bottom: 1px solid $gray-4;
    }
    &__item.active {
      background-color: $gray-light
    }
    &__item.clickable {
      cursor: pointer;
    }
    &__risk-level {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      .alerts-table__main {
        @include Ellipsis;
        max-width: 140px;
      }
      .alerts-table__sub {
        @include Ellipsis;
        display: inline-block;
        max-width: 120px;
      }
      &__cel {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 84px;
        height: 24px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 16px;
        &.low {
          background-color: $gray-1;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23FFA39E' stroke-width='4' stroke-dasharray='10%2c 11' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
        }
        &.medium {
          background: $red-3;
        }
        &.high {
          color: $gray-1;
          background: $red-6;
        }
        &.severe {
          color: $gray-1;
          background: $red-7;
        }
      }
    }
    &__subject {
      width: 156px;
      min-width: 156px;
      max-width: 156px;
    }
    &__amount {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
    }
    &__risk-value {
      width: 110px;
      min-width: 110px;
      max-width: 110px;
    }
    &__client-id {
      margin-left: 4px;
      .copy-dropdown__text {
        @include Ellipsis;
        max-width: 144px;
      }
    }
    &__created-at {
      width: 110px;
    }
    &__event-time {
      width: 110px;
    }
    &__status {
      width: 110px;
      .alerts-table__main {
        @include Ellipsis;
        max-width: 90px;
      }
    }
    &__main {
      font-size: 14px !important;
      line-height: 22px !important;
      font-weight: 400 !important;
    }
    &__sub {
      display: flex;
      font-size: 12px !important;
      line-height: 16px !important;
      height: 16px;
      font-weight: 400 !important;
      color: $gray-8;
    }
    &__category {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
      &__cel {
        .alerts-table__main {
          @include Ellipsis;
          max-width: 160px;
        }
      }
    }
    &.noBorderBottom {
      border-bottom: none;
      td {
        border-bottom: none;
      }
    }
    &__empty {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-tabs {
    display: flex;
    flex-direction: row;

    top: 3px;
    right: 0;
    position: absolute;
    &-wrapper {
      top: 3px;
      right: 0;
      position: absolute;
    }
  }
  &-tab {
    cursor: pointer;
    padding: 0 8px;
    color: $gray-8;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;
    @include Transition;
    &.active {
      border-bottom: 2px solid $main;
      color: $main
    }
    &:hover {
      color: $main;
    }
  }
}

.choose-transfer {
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: 2px;
  border: 1px solid $gray-5;
  color: $gray-6;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  &.error {
    border-color: $red-5;
  }

  &__menu {
    border: 1px solid $gray-5;
    padding: 0;
    max-height: 175px;
    border-radius: 0 0 2px 2px;
    overflow: scroll;
  }
  &__menu &__item {
    border-left: unset;
    border-right: unset;
    &:first-child {
      height: calc(48px + 8px);
      padding-top: 8px;
    }
  }
  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &__not-selected {
    padding: 12px 12px 12px 16px;
    height: 100%;
    &.disabled {
      cursor: not-allowed;
      background: $gray-light;
    }
    &__icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateX(50%);
      rotate: -90deg;
      &.visible {
        rotate: 90deg;
        transform: translateX(-50%);
      }
      path {
        fill: $main;
      }
    }
  }

  &__address  &__item__arrow {
    width: 12px;
    height: 12px;
    margin-left: 8px;
    margin-right: 8px;
  }
  &__address {
    margin-left: auto;
    margin-right: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: end;
  }
  &__item {
    position: relative;
    height: 48px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0 16px;
    cursor: pointer;
    border: 1px solid $gray-5;
    border-bottom: none;
    &:hover:not(.selected) {
      @include Transition;
      background-color: $gray-light;
    }
    &:first-child {
      border-top: none;
    }
    &.selected {
      border: unset;
    }
    .hint-content {
      align-self: auto;
    }
    .copy-dropdown {
      height: auto;
    }
    .copy-dropdown .bn-dropdown__overlay {
      top: 20px;
    }
    &__icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      @include Transition;
      &.visible {
        transform: rotate(180deg);
      }
    }
    &__data {
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      @include Ellipsis;
      width: 130px;
    }
    &__amount {
      color: $gray-9;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    &__name {
      color: $gray-7;
      font-size: 10px;
      line-height: 12px;
    }
    &__input, &__output {
      @include Ellipsis;
      color: $main;
      font-size: 12px;
      line-height: 14px;
      width: 91px;
      &.info {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    &__info-icon {
      margin-left: 4px;
      width: 14px;
      height: 14px;
    }
    &__address {
      margin-left: auto;
      margin-right: 58px;
      white-space: nowrap;
      color: $gray-8;
      font-size: 10px;
      font-style: normal;
      line-height: 14px;
    }
    &__arrow {
      transform: rotate(180deg);
      margin-left: 10px;
      margin-right: 22px;
      width: 14px;
      height: 14px;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .clients {
    &-table {
      &__wrapper {
        padding-bottom: 16px;
        width: 720px;
        overflow-x: scroll;
        .ps__rail-x {
          border-radius: 4px;
          background: $gray-2;
          height: 10px;
        }
      }
    }
  }
}

.aml-checks {
  &-details-risks {
    &__empty {
      min-height: 192px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      div {
        height: 100%;
        display: flex;
        align-items: center;
      }
      span {
        font-weight: 500;
        margin-left: 16px;
      }
      &__icon {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__header-top {
    padding: 19px 24px 24px 24px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $gray-1;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

    div.wrapper {
      gap: 0;
      width: 100%;
      padding: 16px 17px 16px 24px;
      display: flex;
      background: #fff;
      position: absolute;
      top: 56px;
      overflow: hidden;
      z-index: 1;

      .ant-input-affix-wrapper {
        width: 97.8%;
      }

      button {
        transform: translateX(30px);
        margin-right: -100%;
        transition: all 0.3s ease-in-out;
      }
    }

    div.fixed {
      position: fixed;
      top: 0;
    }

    div.small {
      button {
        margin-left: 10px;
        transform: translateX(0);
        margin-right: 0;
      }
    }

    .wrapper-shadow {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    }

    button {
      margin: 0 24px;
      transition: 0.3s ease-in-out !important;
    }

    .minimal-button {
      width: 40px;
      height: 40px;
      padding: 0 12px;
      margin: 0;
    }

    h4 {
      margin: 0 0 90px 0;
      padding: 0;
      color: $gray-9;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    input {
      outline: none;
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  &-check {
    &__wrapper {
      padding: 32px 0;
      background-color: $gray-2;
      border-bottom: 1px solid $gray-4;
      border-top: 1px solid $gray-4;
    }
    display: flex;
    flex-direction: row;

    &__input {
      height: 40px;
      border-radius: 2px;
      margin-top: 0;
      border: 1px solid $gray-5;
      &.error {
        border-color: $red-5;
      }
      &__wrapper {
        width: 100%;
        position: relative;
      }
      &__error {
        transition: 0.5s;
        position: absolute;
        color: $red-5;
        font-size: 14px;
        line-height: 22px;
        opacity: 0;
        bottom: 0;
        @include Transition;
        &.visible {
          opacity: 1;
          bottom: -22px;
        }
      }
    }
    &__btn {
      width: 220px;
      height: 40px;
      margin-left: 32px;
    }
  }

  &-header {
    margin-top: 40px;
  }
  &-filter {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    .select:not(:last-child) {
      margin-right: 16px;
    }
    &__check-type {
      width: 196px;
    }
    &__blockchain {
      width: 196px;
    }
    &__date-picker {
      width: 216px;
    }
    &__clear {
      margin-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      path {
        @include Transition;
      }
      &:hover {
        path {
          fill: $main;
        }
      }
    }
    &__search {
      margin-left: auto;
      width: 220px;
    }
  }

  &-table {
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    width: 100%;
    margin-top: 24px;
    &__loading {
      width: auto;
    }
    tr:not(.first-child) {
      @include Transition;
      &:hover {
        background-color: $gray-light
      }
    }
    th {
      height: 40px;
      padding-left: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      background: $gray-2;
      &:first-child {
        padding-left: 16px;
        padding-right: 8px;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 22px;
          background-color: $gray-4;
        }
      }
    }
    td {
      height: 56px;
      max-height: 56px;
      padding-left: 8px;
      border-bottom: 1px solid $gray-4;
      @include Ellipsis;
      &:first-child {
        padding-left: 12px;
      }
    }
    tr:not(:first-child) {
      border-bottom: 1px solid $gray-4;
    }
    &__item {
      cursor: pointer;
    }
    &__main {
      font-size: 14px;
      line-height: 22px;
      @include Ellipsis;
      &.error {
        color: $red-6;
      }
    }
    &__sub {
      display: flex;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      color: $gray-7;
      @include Ellipsis;
    }

    &__risk-level {
      position: relative;
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      &__loading {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
      }
      &__box {
        width: 84px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        font-size: 12px;
        line-height: 16px;
        &.no_risk, &.none {
          border-radius: 2px;
          border: 1px solid $gray-4;
          background: $accept-light;
        }
        &.low {
          background-color: $gray-1;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23FFA39E' stroke-width='4' stroke-dasharray='10%2c 11' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
        }
        &.medium {
          background: $red-3;
        }
        &.high {
          color: $gray-1;
          background: $red-6;
        }
        &.severe {
          color: $gray-1;
          background: $red-7;
        }
        &.error {
          gap: 5px;
          color: $error-2;
          font-size: 14px;
          line-height: 22px;
        }
      }

    }
    &__check-type {
      width: 180px;
      min-width: 180px;
      max-width: 180px;
    }
    &__blockchain {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
    }
    &__address-or-tx-hash {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
    }
    &__address-or-tx-hash &__main {
      max-width: 156px;
    }
    &__assets {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
    }
    &__created-at {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
    }

    &__status {
      width: 230px;
      min-width: 230px;
      max-width: 230px;
    }
    &__item &__status {
      display: flex;
      align-items: center;
      flex-direction: row;
      &__icon {
        margin-right: 12px;
      }
    }
    &__status &__main {
      width: 180px;
      @include Ellipsis;
    }
    &__amount {
      width: 130px;
      min-width: 130px;
      max-width: 130px;
    }
    &__wallet {
      width: 170px;
      min-width: 170px;
      max-width: 170px;
    }
    &__invoice-id {
      width: 140px;
      min-width: 140px;
      max-width: 140px;
    }
    &__client-id {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
    }
    &__time-left {
      width: 96px;
      min-width: 96px;
      max-width: 96px;
    }
    &__time-left &__main {
      color: $gray-7;
    }
    &__empty {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-show-more {
    &__btn {
      padding: 20px 0;
      width: 100%;
      background: $gray-2;
      border: 1px solid $gray-4;
      border-top: none;
      border-radius: 0 0 16px 16px;

      color: $main;

      &:hover {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
        border-top: none;
      }

      &:focus {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }
    }
    &__skeleton {
      height: 42px !important;
      width: 100% !important;

      border: 0.5px solid $gray-5;
      border-radius: 2px 2px 16px 16px !important;
    }
  }
  &-form {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-selection-search,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-top: 4px;
    }

    &__submit {
      margin-top: 24px;
      height: 40px;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .aml-checks {
    &-filter {
      margin-left: 0;
      &__check-type {
        width: 150px;
      }
      &__blockchain {
        width: 150px;
      }
      &__date-picker {
        width: 216px;
      }
      &__clear {
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__search {
        margin-left: auto;
        width: 140px;
      }
    }
    &-table {
      width: 996px;
      &__content {
        width: calc(996px + (100% - 720px));
        padding-left: 80px;
      }
      &__wrapper {
        overflow: scroll;
        padding-bottom: 16px;
      }
    }
    .ps__rail-x {
      //border-radius: 4px;
      //background: $gray-2;
      //height: 10px;
      //margin-left: 80px;
      //margin-right: calc(100vw - 720px - 80px);
      display: none !important;
    }
  }
}

@media screen and (max-width: $more-md-media) {
  .aml-checks {
    &-table {
      width: 996px;
      &__content {
        padding-left: calc((100vw - 720px) / 2);
        padding-right: calc((100vw - 720px) / 2);
      }
    }
    .ps__rail-x {
      margin-left: calc((100vw - 720px) / 2);
      margin-right: calc((100vw - 720px) / 2);
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  @keyframes AnimationName {
    0% {
      background-position: calc(6px / sin(45deg)) 0;
    }
  }

  .mobile-filter {
    .aml-checks-filter {
      &__check-type {
        width: 100%;
      }
      &__blockchain {
        margin-top: 16px;
        width: 100%;
      }
      &__date-picker {
        margin-top: 16px;
        width: 100%;
      }
      &__search {
        margin-top: 16px;
        width: 100%;
      }
      &__clear {
        text-align: center;
        margin-left: auto;
        cursor: pointer;
        font-size: 14px;
        line-height: 22px;
        text-decoration-line: underline;
        color: $main;
      }
    }
  }
  .aml-checks {
    &-content {
      margin-top: 0;
      position: relative;
    }
    .ps__rail-x {
      margin-left: auto;
      margin-right: auto;
    }
    &-list {
      padding-top: 24px;
    }
    &-mobile {
      &-list-item {
        height: 56px;
        display: flex;
        align-items: center;
        flex-direction: row;
        border-top: 1px solid $gray-4;
        background-color: $gray-1;
        &:last-child {
          border-bottom: 1px solid $gray-4;
        }
        &__risk-level {
          height: 100%;
          width: 12px;
          min-width: 12px;
          border-left: 1px solid $gray-4;
          &.low {
            background: repeating-linear-gradient(
                45deg,
                $gray-1,
                $gray-1 3px,
                $red-3 3px,
                $red-3 6px
            );
          }
          &.medium {
            background: $red-3;
          }
          &.high {
            color: $gray-1;
            background: $red-6;
          }
          &.severe {
            color: $gray-1;
            background: $red-7;
          }
          &.checking {
            background: repeating-linear-gradient(
                45deg,
                $gray-1,
                $gray-1 3px,
                $gray-3 3px,
                $gray-3 6px
            );
            background-size: calc(6px / sin(45deg)) 100%;
            animation: AnimationName 1s linear infinite reverse;
          }
          &.error {
            border-right: 2px solid $red-6;
          }
        }
        &__content {
          margin-right: 14px;
          padding-left: 12px;
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          height: 100%;
          border-left: 1px solid $gray-4;
        }
        &__cel {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &__main {
          width: 100%;
          font-size: 14px;
          line-height: 22px;
          @include Ellipsis;
          &.error {
            color: $error-2;
          }
        }
        &__sub {
          width: 100%;
          color: $gray-8;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          @include Ellipsis;
        }
        &__check-type {
          @include Ellipsis;
          text-align: start;
          justify-content: start;
          font-size: 12px;
          line-height: 16px;
          align-items: flex-start;
        }
        &__amount-risky-value {
          padding-left: 8px;
          align-items: flex-end;
          margin-left: auto;
        }
        &__amount-risky-value &__main, &__amount-risky-value &__sub {
          text-align: end;
        }
        &__amount-risky-value &__sub {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
        &__created-at {
          margin-top: 3px;
          color: $gray-7;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    &-show-more {
      margin-top: 32px;
      width: 100%;
      padding: 0 16px;
      &__btn {
        padding: 20px 0;
        border: 1px solid $gray-5;
        border-radius: 2px;
      }
      &__skeleton {
        border-radius: 2px !important;
      }
    }
    &-mobile-loader {
      max-height: calc(100vh - 240px);
    }
    .bn-pagination {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .alerts {
    &-tabs {
      position: static;
      padding: 24px 24px 0;
      justify-content: normal;
      flex-direction: row;

      span.fast-filter-icon {
        position: absolute;
        background: $gray-light;
        cursor: pointer;
        right: 16px;
        bottom: -3px;
        svg path {
          fill: $gray-8;
        }
      }

      &__warapper {
        padding-bottom: 16px;
      }
    }
    &-mobile-list-item {
      height: 56px;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-top: 1px solid $gray-4;
      background-color: $gray-1;
      &:last-child {
        border-bottom: 1px solid $gray-4;
      }
      &.isClientDetail {
        padding: 0 16px;
        .alerts-table__risk-level__box {
          height: 22px;
          width: 62px;
        }
      }
      &__risk-level {
        height: 100%;
        width: 12px;
        min-width: 12px;
        border-left: 1px solid $gray-4;
        &.low {
          background: repeating-linear-gradient(
              45deg,
              $gray-1,
              $gray-1 3px,
              $red-3 3px,
              $red-3 6px
          );
        }
        &.medium {
          background: $red-3;
        }
        &.high {
          color: $gray-1;
          background: $red-6;
        }
        &.severe {
          color: $gray-1;
          background: $red-7;
        }
      }
      &__content {
        margin-right: 14px;
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-left: 1px solid $gray-4;
      }
      &__cel {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__main {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        @include Ellipsis;
      }
      &__sub {
        width: 100%;
        color: $gray-8;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        @include Ellipsis;
      }
      &__created-at {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-left: 7px;
        width: 82px;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        color: $gray-8;
        span {
          &:first-child {
            color: $gray-9;
            line-height: 20px;
          }
        }
      }
      &.isClientDetail &__created-at {
        color: $gray-8;
      }
      &.isClientDetail &__sub {
        line-height: 16px;
        font-size: 12px;
      }
      &__type-category {
        @include Ellipsis;
        text-align: start;
        justify-content: start;
        font-size: 12px;
        line-height: 16px;
        align-items: flex-start;
        padding-left: 8px;
      }
      &.isClientDetail &__type-category {
        margin-left: 16px;
      }
      &__amount-risky-value {
        padding-left: 8px;
        align-items: flex-end;
        margin-left: auto;
      }
      &.isClientDetail &__amount-risky-value{
        @include Ellipsis;
      }
      &__amount-risky-value &__main, &__amount-risky-value &__sub {
        text-align: end;
      }
      &.isClientDetail &__amount-risky-value &__sub {
        text-align: end;
      }
      &__amount-risky-value &__sub {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
    &-mobile-loader {
      max-height: calc(100vh - 240px);
    }
  }
}


@media screen and (max-width: $max-md-media) {
  .alerts {
    &-table {
      width: 996px;
      &__content {
        width: calc(996px + (100% - 720px));
        padding-left: 80px;
      }
      &__wrapper {
        overflow: scroll;
        padding-bottom: 16px;
      }
    }
    &-tabs {
      justify-content: flex-end;
      flex-direction: row;
      &__menu {
        margin-left: 10px;
        margin-right: -19px;
        height: 24px;
        transform: rotate(90deg);
        &__trigger {
          display: inline-block;
          width: 100%;
          svg, path {
            fill: $main;
          }
        }

      }
    }
    &-tab {
      flex-shrink: 0;
    }
  }
  .bn-dropdown__menu li.selected {
    color: $main
  }
}

@media screen and (max-width: $more-md-media) {
  .alerts {
    &-table {
      width: 996px;
      &__content {
        padding-left: calc((100vw - 720px) / 2);
        padding-right: calc((100vw - 720px) / 2);
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .alerts {
    &-tabs {
      position: static;
      padding: 24px 24px 0;
      justify-content: normal;
      flex-direction: row;

      span.fast-filter-icon {
        position: absolute;
        background: $gray-light;
        cursor: pointer;
        right: 16px;
        bottom: -3px;
        svg path {
          fill: $gray-8;
        }
      }

      &__warapper {
        padding-bottom: 16px;
      }
    }
    &-mobile-list-item {
      height: 56px;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-top: 1px solid $gray-4;
      background-color: $gray-1;
      &:last-child {
        border-bottom: 1px solid $gray-4;
      }
      &.isClientDetail {
        padding: 0 16px;
        .alerts-table__risk-level__box {
          height: 22px;
          width: 62px;
        }
      }
      &__risk-level {
        height: 100%;
        width: 12px;
        min-width: 12px;
        border-left: 1px solid $gray-4;
        &.low {
          background: repeating-linear-gradient(
              45deg,
              $gray-1,
              $gray-1 3px,
              $red-3 3px,
              $red-3 6px
          );
        }
        &.medium {
          background: $red-3;
        }
        &.high {
          color: $gray-1;
          background: $red-6;
        }
        &.severe {
          color: $gray-1;
          background: $red-7;
        }
      }
      &__content {
        margin-right: 14px;
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-left: 1px solid $gray-4;
      }
      &__cel {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__main {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        @include Ellipsis;
      }
      &__sub {
        width: 100%;
        color: $gray-8;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        @include Ellipsis;
      }
      &__created-at {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-left: 7px;
        width: 82px;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        color: $gray-8;
        span {
          &:first-child {
            color: $gray-9;
            line-height: 20px;
          }
        }
      }
      &.isClientDetail &__created-at {
        color: $gray-8;
      }
      &.isClientDetail &__sub {
        line-height: 16px;
        font-size: 12px;
      }
      &__type-category {
        @include Ellipsis;
        text-align: start;
        justify-content: start;
        font-size: 12px;
        line-height: 16px;
        align-items: flex-start;
        padding-left: 8px;
      }
      &.isClientDetail &__type-category {
        margin-left: 16px;
      }
      &__amount-risky-value {
        padding-left: 8px;
        align-items: flex-end;
        margin-left: auto;
      }
      &.isClientDetail &__amount-risky-value{
        @include Ellipsis;
      }
      &__amount-risky-value &__main, &__amount-risky-value &__sub {
        text-align: end;
      }
      &.isClientDetail &__amount-risky-value &__sub {
        text-align: end;
      }
      &__amount-risky-value &__sub {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
    &-mobile-loader {
      max-height: calc(100vh - 240px);
    }
  }
}
