@media screen and (max-width: $max-md-media) {
  .transactions-list {
    &__title-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__item-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &__skeleton {
      width: 100%;
      &__wrapper {
        width: 720px;
        margin: auto;
      }

      &__title > span {
        margin: 24px 0 24px 15px;
      }

      &__items > div > span {
        border-radius: 0 !important;
        width: 100% !important;
      }
    }
    &__show-more {
      &__wrapper {
        margin: auto;
        width: 720px;
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .transactions-list {
    &__item-wrapper {
      background-color: $gray-light !important;
    }
    &__show-more {
      &__wrapper {
        width: auto;
        margin: 16px 11px 0 11px;
      }
      border-radius: 2px;
    }
    &__skeleton {
      &__wrapper {
        width: 100%;
      }

      width: 100%;
      &__title {
        display: flex;
        justify-content: center;
      }

      &__title > span {
        margin: 18px auto;
      }
    }
  }
}
