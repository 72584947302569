.drop-file-input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 12px 16px 12px;
  max-width: 460px;
  width: 100%;
  border: 1px dashed $gray-5;
  border-radius: 4px;
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  &__text {
    text-align: center;
    max-width: 312px;
    width: 100%;
    color: $gray-8;
    font-size: 12px;
    line-height: 14px;
  }
  &__file-text-wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;
    svg path {
      fill: $main;
    }
  }
  &__file-text {
    margin-left: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $main;
  }
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-preview {
  margin-top: 16px;
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 24px;
    }
    &-info {
      @include Ellipsis;
      display: flex;
      align-items: center;
      width: 95%;
      p {
        color: $main;
        font-size: 14px;
        line-height: 16px;
        text-decoration-line: underline;
        //max-width: 245px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      a {
        margin: 0 16px 0 8px;
        width: 16px;
        height: 16px;
      }
      svg {
        width: 100%;
        height:100%;
      }
      svg path {
        fill: $main;
      }
    }
    &-del {
      @include FlexCenter;
      width: 16px;
      height: 16px;
      background: $gray-5;
      cursor: pointer;
      border-radius: 50%;
      &:hover {
        opacity: 0.8;
      }
      svg {
        width: 6px;
        height: 6px;
        path {
          fill: $gray-1;
        }
      }
    }
  }
}

.drop-file-input-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__input-wrapper {
    position: relative;
    width: 146px;
    cursor: pointer;
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  &__text {
    font-size: 12px;
    line-height: 14px;
    color: $gray-8;
    width: 140px;
  }
  &__file-text-wrapper {
    @include FlexCenter;
    border: 1px solid $gray-5;
    border-radius: 2px;
    width: 100%;
    height: 32px;
    svg {
      width: 14px;
      height: 14px;
    }
    svg path {
      fill: $main;
    }
  }
  &__file-text {
    margin-left: 10px;
    font-size: 14px;
    line-height: 22px;
    color: $main;
  }
}

